import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert, FormText } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Card from '../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';

const SamplePage = () => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [totalCost, setTotalCost] = useState(0);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [accounts, setAccounts] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/accounts`;
    }, [apiUrl]);

    const memoizedApiUrl3 = useMemo(() => {
        return `${apiUrl}/feeds`;
    }, [apiUrl]);

    const memoizedApiUrl4 = useMemo(() => {
        return `${apiUrl}/suppliers`;
    }, [apiUrl]);

    const generateTransactionId = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000);
        return `SF-${random}`;
    };

    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    useEffect(() => {
        fetchAccounts();
    }, [memoizedApiUrl2]);

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(memoizedApiUrl2);
            const accounts = response.data;

            // const filteredData = accounts.filter((tag) => tag.farm === farm);

            // const filteredAccounts = accounts.filter(account => account.accountName !== 'Income');

            setAccounts(accounts);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    const handleSelectChange = (name, selectedOption) => {
        setData({ ...data, [name]: selectedOption.value, transactionId: generateTransactionId() });
    };

    console.log(data);
    useEffect(() => {
        const fetchVaccines = async () => {
            try {
                const response = await axios.get(memoizedApiUrl4);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((sup) => ({
                    value: sup.name,
                    label: sup.name
                }));
                console.log(options);
                setSupplierOptions(options);
            } catch (error) {
                console.error('Error fetching vaccines:', error);
            }
        };

        fetchVaccines();
    }, [memoizedApiUrl4]);

    // useEffect(() => {
    //     const calculateTotalCost = () => {
    //       const totalCost = data.quantity * data.costper;
    //       setTotalCost(totalCost)
    //       setData((prevData) => ({ ...prevData, totalCost }));
    //     };

    //     calculateTotalCost();
    //   }, [data.quantity, data.costper]);

    const handleInput = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value, sup_name: selectedSupplier, userName: account.user.firstname, userId: account.user.id });
        console.log(data);
    };

    const formData = new FormData();
    formData.append('image', selectedImage);
    formData.append('sup_name', selectedSupplier);
    // formData.append('food_name', data.food_name);
    // formData.append('quantity', data.quantity);
    // formData.append('costper', data.costper);
    formData.append('totalCost', data.totalCost);
    formData.append('remarks', data.remarks);
    formData.append('bankAccount', data.bankAccount);
    formData.append('transactionId', data.transactionId);
    formData.append('farm', data.farm);
    formData.append('recorded', data.recorded);
    formData.append('userId', data.userId);
    formData.append('userName', data.userName);

    const handleFeed = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/add-food`, formData);

            if (response.data.message) {
                setSuccess(`Food added successfully`);
                setTimeout(() => {
                    setSuccess(null);
                }, 10000);
                toast.success(`Food added successfully`);
                formRef.current.reset();
                setTotalCost(null);
                setShowConfirmationModal(false);
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setError(`${error.response.data.message}`);
                toast.error(`${error.response.data.message}`);
                setShowConfirmationModal(false);
            } else {
                setError('An error occurred. Please try again later.');
                setShowConfirmationModal(false);
            }
        }
    };

    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Form onSubmit={handleFeed} ref={formRef}>
                <Row>
                    <Col>
                        <Card title="Add food" isOption>
                            <Row>
                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                    <Form.Label>Farm</Form.Label>
                                    <Select
                                        options={farmOptions}
                                        onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                        isSearchable
                                        name="farm"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Supplier</Form.Label>
                                    <CreatableSelect
                                        onChange={(selectedOption) => setSelectedSupplier(selectedOption.value)}
                                        options={supplierOptions}
                                        isSearchable
                                        name="sup_name"
                                        placeholder="-- Select or Type Supplier --"
                                        required
                                    />
                                    <FormText>Type name, click Create / hit Enter to insert added name</FormText>
                                </Form.Group>
                                {/* <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                            <Form.Label>Food Name</Form.Label>
                            <Form.Control 
                            size="sm" 
                            required 
                            onChange={handleInput} 
                            type="text" 
                            name='food_name'
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                            <Form.Label>Stock Quantity in Kgs</Form.Label>
                            <Form.Control 
                            size="sm" 
                            required 
                            onChange={handleInput} 
                            type="number" 
                            name='quantity'
                            />                            
                        </Form.Group>

                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                            <Form.Label>Cost per Stock</Form.Label>
                            <Form.Control 
                            size="sm" 
                            required 
                            onChange={handleInput} 
                            type="number" 
                            name='costper'
                            />
                            
                        </Form.Group> */}

                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Total Cost</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={handleInput}
                                        type="number"
                                        // value={totalCost}
                                        name="totalCost"
                                        // disabled
                                    />
                                </Form.Group>

                                <Col md={6} sm={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>Expense Account:</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => handleSelectChange('bankAccount', selectedOption)}
                                            options={accounts.map((account) => ({
                                                value: account.description,
                                                label: (account.accountNumber, account.description)
                                            }))}
                                            placeholder="Select account"
                                            required
                                        />
                                    </Form.Group>
                                </Col>

                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                    <Form.Label>Supporting Document</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={handleImageChange}
                                        name="image"
                                        accept="image/*"
                                        // required
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control type="date" onChange={handleInput} name="recorded" required />
                                </Form.Group>

                                <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                    <Form.Label>Remarks/Notes</Form.Label>
                                    <Form.Control as="textarea" required onChange={handleInput} type="text" name="remarks" />
                                </Form.Group>
                                {/* {createPermission && ( */}
                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                        <Button type="submit">Submit</Button>
                                    </Form.Group>
                                {/* )} */}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;

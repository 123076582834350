import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import config from './../../config';
import Card from '../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';

const SamplePage = ({ onHide }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [batchOptions, setBatchOptions] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState([]);
    const [numOfTrays, setNumOfTrays] = useState(0);
    const [remainingEggs, setRemainingEggs] = useState(0);
    const [remaining, setRemaining] = useState(0);
    const [selectedFarm, setSelectedFarm] = useState(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const handleInput = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value, batch: selectedBatch, userId: account.user.id, userName: account.user.firstname });
    };

    const handleItem = (e) => {
        const { name, value } = e.target;
        const collectedEggs = parseInt(value); // Add the remaining eggs from the database
        // + remainingEggs
        const trays = Math.floor(collectedEggs / 30);
        const remaining = collectedEggs % 30;

        setData({ ...data, [name]: value, batch: selectedBatch, numOfTrays: trays, remainingEggs: remaining });
        setNumOfTrays(trays);
        setRemaining(remaining);
    };

    useEffect(() => {
        console.log(data);
    }, []);

    const handleInputs = (selectedOption) => {
        const selectedBatch = selectedOption.value;
        const farm = selectedOption.farm;
        setSelectedBatch(selectedBatch);
        setSelectedFarm(farm);
        setData({ ...data, farm: farm, batch: selectedBatch });
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const successParam = urlParams.get('success');

        if (successParam === 'true') {
            setSuccess('Eggs updated successfully');
            toast.success('Eggs updated successfully');
            setTimeout(() => {
                setSuccess(null);
            }, 10000);
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, []);

    const memoizedApiUrl = useMemo(() => {
        return `${apiUrl}/poultry`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(memoizedApiUrl);
                const data = response.data;

                const layerBatches = data.filter((tag) => tag.type === 'Layers');

                const options = layerBatches.map((tag) => ({
                    value: tag.batch,
                    label: `${tag.batch} - ${tag.farm}`,
                    farm: tag.farm
                }));
                setBatchOptions(options);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };

        fetchTags();
    }, [memoizedApiUrl]);

    useEffect(() => {
        const fetchRemainingEggsFromDB = async () => {
            try {
                const response = await axios.get(`${apiUrl}/eggs/remaining`);
                const remainingEggs = response.data[0].remainingEggs;
                setRemainingEggs(remainingEggs);
            } catch (error) {
                console.error('Error fetching remaining eggs from the database:', error);
            }
        };

        fetchRemainingEggsFromDB();
    }, [remainingEggs, apiUrl]);
    // console.log(data);
    const handleEggs = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/eggs/update`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(response.data.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    setShowConfirmationModal(false);
                    toast.success(response.data.message);
                    onHide();
                }
                // console.log(response);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    // Display error message received from the server
                    setError(error.response.data.message);
                    console.log(error.response.data.message);
                    toast.error(error.response.data.message);
                } else {
                    // Display generic error message for any other errors
                    setError('An error occurred while processing your request.');
                    console.log('An error occurred while processing your request.', error);
                    toast.error('An error occurred while processing your request.');
                }
                setShowConfirmationModal(false);
            });
    };

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Modal show={showModal} onHide={onHide} size="lg" centered>
                <Form onSubmit={handleEggs}>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Modal.Header closeButton>
                        <Modal.Title>Eggs Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Layer's Batch No.</Form.Label>
                                        <Select
                                            onChange={handleInputs}
                                            options={batchOptions}
                                            isSearchable
                                            name="batch"
                                            placeholder="-- Select batch --"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Eggs Collected</Form.Label>
                                        <Form.Control required onChange={handleItem} type="number" name="collected" />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Trays</Form.Label>
                                        <Form.Control readOnly value={numOfTrays} name="trays" />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Remaining Eggs</Form.Label>
                                        <Form.Control readOnly value={remaining} name="remaining" />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Broken Eggs</Form.Label>
                                        <Form.Control required onChange={handleInput} type="number" name="spoilt" />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Double Yolked Eggs</Form.Label>
                                        <Form.Control required onChange={handleInput} type="number" name="double_yolk" />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>Farm</Form.Label>
                                        <Form.Control
                                            required
                                            value={selectedFarm}
                                            onChange={handleInput}
                                            type="text"
                                            name="farm"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control size="sm" required onChange={handleInput} type="date" name="recorded" />
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                        <Form.Label>Remarks/Notes</Form.Label>
                                        <Form.Control as="textarea" required onChange={handleInput} type="text" name="remarks" />
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onHide} variant="secondary">
                            Cancel
                        </Button>
                        {createPermission && <Button type="submit">Submit</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>
            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Alert, Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import axios from 'axios';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';
import Creatable, { useCreatable } from 'react-select/creatable';

const BasicTypography = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedPig, setSelectedPig] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const formRef = useRef(null);
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [nameOptions, setNameOptions] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState({});
    const [accounts, setAccounts] = useState([]);

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/assets`;
    }, [apiUrl]);

    const memoizedApiUrl3 = useMemo(() => {
        return `${apiUrl}/accounts`;
    }, [apiUrl]);

    useEffect(() => {
        fetchAccounts();
    }, [memoizedApiUrl3]);

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(memoizedApiUrl3);
            const accounts = response.data;

            // const filteredData = accounts.filter((tag) => tag.farm === farm);

            const filteredAccounts = accounts.filter(account => account.type === 'Assets');

            setAccounts(filteredAccounts);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl2);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.asset_name,
                    label: farm.asset_name
                }));

                setNameOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const generateTransactionId = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000);
        return `SF-${random}`;
    };

    const handleInput = (name, value) => {
        setData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            userId: account.user.id,
            userName: account.user.firstname
        }));
    };
    
    const handleSelectChange = (name, selectedOption) => {
        setData({ ...data, [name]: selectedOption.value });
    };

    console.log(data);

    const formData = new FormData();
    formData.append('image', selectedImage);
    formData.append('remarks', data.remarks);
    formData.append('cost', data.cost);
    formData.append('type', data.type);
    formData.append('asset_name', data.asset_name);
    // formData.append('transactionId', generateTransactionId());
    formData.append('recorded', data.recorded);
    formData.append('farm', data.farm);
    formData.append('userId', data.userId);
    formData.append('userName', data.userName);
    formData.append('vendor', data.vendor);
    formData.append('bankAccount', data.bankAccount);

    const handlePoultry = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        e.preventDefault();
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/assets/new`, formData)
            .then((response) => {
                if (response.data) {
                    setSuccess(response.data.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(response.data.message);
                    setData({});
                    setSelectedFarm({});
                    setSelectedPig(null);
                    formRef.current.reset();
                    setShowConfirmationModal(false);
                    setSelectedName(null);
                    onCancel();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal(false);
                }
                console.log(error);
                setShowConfirmationModal(false);
            });
    };

    const pigOptions = [
        { value: 'Land', label: 'Land' },
        { value: 'Buildings & Infrastructure', label: 'Buildings & Infrastructure' },
        { value: 'Equipment and Machinery', label: 'Equipment and Machinery' }
    ];

    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const handleCancelConfirmation = () => {
        setShowConfirmationModal(false);
    };

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get(`${apiUrl}/suppliers`);
                const data = response.data;

                const filteredData = data.filter((tag) => tag.speciality === 'Assets Supplier');

                // const options = data.map((supplier) => ({
                const options = filteredData.map((supplier) => ({
                    value: supplier.name,
                    label: supplier.name
                }));
                setSupplierOptions(options);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };

        fetchSuppliers();
    }, []);

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Asset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form onSubmit={handlePoultry} ref={formRef}>
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>Farm</Form.Label>
                                        <Select
                                            options={farmOptions}
                                            value={farm}
                                            onChange={(selectedOption) => setSelectedFarm(handleInput('farm', selectedOption.value))}
                                            isSearchable
                                            name="farm"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Vendor/Supplier</Form.Label>
                                        <Select
                                            value={selectedSupplier}
                                            onChange={(selectedOption) => setSelectedSupplier(handleInput('vendor', selectedOption.value))}
                                            options={supplierOptions}
                                            isSearchable
                                            name="vendor"
                                            placeholder="-- Select a supplier --"
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Type</Form.Label>
                                        <Select
                                            size="sm"
                                            required
                                            value={selectedPig}
                                            options={pigOptions}
                                            isSearchable
                                            onChange={(selectedOption) => setSelectedPig(handleInput('type', selectedOption.value))}
                                            type="text"
                                            name="type"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Name</Form.Label>
                                        <Creatable
                                            size="sm"
                                            required
                                            value={selectedName}
                                            options={nameOptions}
                                            isSearchable
                                            onChange={(selectedOption) => setSelectedName(handleInput('asset_name', selectedOption.value))}
                                            // onChange={(e) => handleInput('asset_name', e.target.value)}
                                            type="text"
                                            name="asset_name"
                                            placeholder="eg. Tractors, greenhouses, barns"
                                        />
                                        {/* <Form.Control
                                        size="sm"
                                        required
                                        onChange={(e) => handleInput('asset_name', e.target.value)}
                                        type="text"
                                        name="asset_name"
                                        placeholder="eg. Tractors, greenhouses, barns"
                                    /> */}

                                        <Form.Text>Create or Select</Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Cost</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('cost', e.target.value)}
                                            type="number"
                                            name="cost"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>Expense Account:</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => handleSelectChange('bankAccount', selectedOption)}
                                            options={accounts.map((account) => ({
                                                value: account.description,
                                                label: account.description
                                            }))}
                                            placeholder="Select account"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>Receipt Document</Form.Label>
                                        <Form.Control type="file" onChange={handleImageChange} name="image" accept="image/*" required />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('recorded', e.target.value)}
                                            type="date"
                                            name="recorded"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            onChange={(e) => handleInput('remarks', e.target.value)}
                                            type="text"
                                            name="remarks"
                                        />
                                    </Form.Group>
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default BasicTypography;

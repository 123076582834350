import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Form, Button, Alert, Table, Badge, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ModuleNotification from '../../../components/Widgets/Statistic/Notification';
// import config from '../../../config';
import axios from 'axios';
// import Calendar from './Calendar';
// import EventForm from './EventForm';
// import { usePermissions } from '../../../permissions';
import DataTable from 'react-data-table-component';
import { usePermissions } from '../../permissions';
import config from '../../config';
import EventForm from '../ui-elements/basic/EventForm';

const MessageReport = () => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [showEventForm, setShowEventForm] = useState(false);
    const userId = account.user.id;
    const [searchText, setSearchText] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedEventType, setSelectedEventType] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [rowToUpdate, setRowToUpdate] = useState(null);
    
    useEffect(() => {
        axios
            .get(`${apiUrl}/combined-email-logs`)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const viewImage = (file) => {
        if (file) {
            // Replace backslashes with forward slashes
            const normalizedFile = file.replace(/\\/g, '/');
            // Remove surrounding double quotes
            console.log(normalizedFile);
            const cleanFile = normalizedFile.replace(/^"|"$/g, '');
            // Extract the filename
            console.log(cleanFile);
            const filename = cleanFile.split('/').pop();
            // Open the image URL in a new tab
            console.log(filename);
            window.open(`${apiUrl}/uploads/${filename}`, '_blank');
        }
    };

    const columns = [
        { name: 'ID', selector: (row) => row.id, sortable: true, wrap: true },
        { name: 'Category', selector: (row) => row.category || 'Customized', sortable: true, wrap: true },
        { name: 'Title', selector: (row) => row.title, sortable: true, wrap: true },
        { name: 'Message', selector: (row) => row.message, sortable: true, width: '250px', wrap: true },
        { name: 'Scheduled', selector: (row) => (row.scheduled ? 'Yes' : 'No'), sortable: true, wrap: true },
        {
            name: 'Scheduled Date',
            selector: (row) => (row.scheduled_date ? new Date(row.scheduled_date).toLocaleString() : '-'),
            sortable: true,
            wrap: true
        },
        { name: 'Created At', selector: (row) => new Date(row.createdAt).toLocaleString(), sortable: true, wrap: true },
        {
            name: 'Action',
            cell: (row) => (
                <div>
                    {row.attachments ? (
                        <Button onClick={() => viewImage(row.attachments)} variant="info">
                            <i className="feather icon-eye" />
                        </Button>
                    ) : (
                        <Button variant="secondary" disabled>
                            <i className="feather icon-slash" />
                        </Button>
                    )}
                </div>
            )
            // ignoreRowClick: true,
            // allowOverflow: true,
            // button: true
            // right: true
        }
    ];

    const ExpandableComponent = ({ data }) => (
        <div style={{ padding: '10px 20px' }}>
            <h6>Email Logs</h6>
            <DataTable
                columns={[
                    { name: 'ID', selector: (row) => row.id, sortable: true, wrap: true },
                    { name: 'Email ID', selector: (row) => row.email_id, sortable: true, wrap: true },
                    { name: 'Email', selector: (row) => row.email, sortable: true, wrap: true },
                    {
                        name: 'Status',
                        selector: (row) => <Badge variant={row.status === 'success' ? 'success' : 'danger'}>{row.status}</Badge>,
                        sortable: true,
                        wrap: true
                    },
                    { name: 'Error', selector: (row) => row.error || '-', sortable: true, wrap: true, width: '450px' },
                    { name: 'Created At', selector: (row) => new Date(row.createdAt).toLocaleString(), sortable: true, wrap: true }
                ]}
                data={data.logs}
                // pagination
                highlightOnHover
                striped
                dense
                noHeader
                customStyles={{
                    headRow: {
                        style: {
                            backgroundColor: '#f1f1f1', 
                            textTransform: 'uppercase',
                        }
                    },
                    // TableCell: {
                    //     style: {
                    //         fontSize: '8px'
                    //     }
                    // }
                }}
            />
        </div>
    );

    const filteredData = data.filter((item) => {
        // Check if the item matches the search text
        const matchesSearchText =
            !searchText ||
            Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()));

        return matchesSearchText;
        // return matchesSearchText && matchesStatusFilter && matchesEventTypeFilter;
    });

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                    {/* <Card> */}
                    {/* <Card.Header>
                            <Card.Title as="h5">Email Logs</Card.Title>
                        </Card.Header> */}
                    {/* <Card.Body> */}
                    <Row>
                        {/* <Col sm={4} lg={3} className="mb-2">
                                    <Button disabled={!createPermission} variant="primary" onClick={() => setShowEventForm(true)}>
                                        <span className="feather icon-plus"></span> Add Event
                                    </Button>
                                    {showEventForm && (
                                        <EventForm
                                            event={selectedEvent}
                                            onAddOrEditEvent={addOrEditEvent}
                                            onDeleteEvent={deleteEvent}
                                            onClose={handleCloseEventForm}
                                        />
                                    )}
                                </Col>
                                <Col sm={4} lg={3} className="mb-2">
                                    <Form.Group controlId="eventTypeFilter">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="dropdown-event-type">
                                                {selectedEventType || 'Filter by Event Type'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setSelectedEventType('event')}>Event</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setSelectedEventType('task')}>Task</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setSelectedEventType('')}>Clear Filter</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Form.Group>
                                </Col>
                                <Col sm={4} lg={3} className="mb-2">
                                    <Form className="mb-3">
                                        <Form.Group controlId="statusFilter">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="primary" id="dropdown-status">
                                                    {selectedStatus || 'Filter by Status'}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => setSelectedStatus('Upcoming')}>Upcoming</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setSelectedStatus('Ongoing')}>Ongoing</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setSelectedStatus('Ended')}>Ended</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setSelectedStatus('')}>Clear Filter</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </Form>
                                </Col> */}
                        {/* <Col sm={12} lg={3} className="mt-2"> */}
                        <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicName">
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Form.Group>
                        {/* </Col> */}
                    </Row>
                    <Row>
                        <Col>
                            <DataTable
                                // title="Combined Email Logs"
                                columns={columns}
                                data={filteredData}
                                expandableRows
                                expandableRowsComponent={ExpandableComponent}
                                pagination
                                highlightOnHover
                                striped
                                // noHeader
                                dense
                                bordered
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Rows per page:'
                                }}
                                paginationResetDefaultPage={true}
                                paginationTotalRows={events.length}
                                // expandableRows
                                // selectableRows
                                onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                                customStyles={{
                                    headRow: {
                                        style: {
                                            backgroundColor: '#279e6c', // Green background
                                            color: '#fff', // White text
                                            textTransform: 'uppercase', // Uppercase text
                                            // fontSize: '10px', // Adjust font size for header row
                                            minHeight: '1.5rem',
                                            paddingTop: '8px',
                                            paddingBottom: '8px'
                                        }
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    {/* </Card.Body>
                    </Card> */}
                </Col>
            </Row>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Completion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to mark this as completed?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    {/* <Button variant="primary" onClick={handleConfirm}>
                        Confirm
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default MessageReport;

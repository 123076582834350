import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import config from "../../../config";
import InvoiceFormModal from "./InvoiceFormModal";
import { addDays, endOfMonth, format } from "date-fns";

const Invoice = () => {
  const [show, setShow] = useState(false);
    const [data, setData] = useState({});
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;

  useEffect(() => {
    const fetchStakeholders = async () => {
      try {
        const response = await axios.get(`${apiUrl}/stakeholders`);
        const datas = response.data;
        const dataOptions = datas.filter((d) => d.type === data.recipientType);
        const options = dataOptions.map((stakeholder) => ({
          value: stakeholder.id,
          label: stakeholder.name,
        }));
        data(options);
      } catch (error) {
        console.error("Error fetching stakeholders:", error);
      }
    };

    fetchStakeholders();

  }, [data.recipientType]);



  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        Create Invoice
      </Button>
      <InvoiceFormModal show={show} onHide={() => setShow(false)} />
    </>
  );
};

export default Invoice;

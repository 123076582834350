import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import config from '../../../config';
import { usePermissions } from '../../../permissions';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';

const TransactionsTable = ({ data }) => {
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [searchText, setSearchText] = useState('');

    // Define table columns
    const columns = [
        {
            name: 'TimeSTamp',
            selector: (row) => row.createdAt,
            sortable: true,
            format: (row) => (row.createdAt ? new Date(row.createdAt).toLocaleString() : 'null') // Format date
        },
        {
            name: 'Transaction ID',
            selector: (row) => row.transactionId,
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => row.paymentStatus,
            sortable: true
        },
        {
            name: 'Due Date',
            selector: (row) => row.dueDate,
            sortable: true,
            format: (row) => (row.dueDate ? new Date(row.dueDate).toLocaleDateString() : 'null') // Format date
        },
        {
            name: 'Confirmed',
            selector: (row) => row.confirmDate,
            sortable: true,
            format: (row) => (row.confirmDate ? new Date(row.confirmDate).toLocaleDateString() : 'null') // Format date
        },
        {
            name: 'To Pay(KES)',
            selector: (row) => row.toPay,
            sortable: true,
            format: (row) =>
                row.toPay
                    ? `${row.toPay.toLocaleString(undefined, {
                          minimumFractionDigits: 2
                      })}`
                    : '0.00' // Format amount
        },
        {
            name: 'Paid Amount(KES)',
            selector: (row) => row.paidAmount,
            sortable: true,
            format: (row) =>
                row.paidAmount
                    ? `${row.paidAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2
                      })}`
                    : '0.00' // Format amount
        }
    ];

    // const filteredData = searchText
    //     ? transactions?.filter((item) =>
    //           Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
    //       )
    //     : transactions;

    const filteredData = searchText
        ? data?.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : data;

    // Add unique keys dynamically to ensure there are no duplicate keys
    const dataWithUniqueKeys = filteredData?.map((item, index) => ({
        ...item,
        uniqueKey: `${item.createdAt || 'defaultKey'}-${index}` // Combine `createdAt` and index
    }));

    return (
        <div>
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={3}>
                            {data && data.length > 0 && (
                                <Row>
                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasicName">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search..."
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <hr />
                </Col>

                <Col sm={12}>
                    <DataTable
                        // title="Transaction List"
                        columns={columns}
                        data={dataWithUniqueKeys}
                        keyField="uniqueKey"
                        // progressPending={loading}
                        pagination
                        highlightOnHover
                        striped
                        dense
                        bordered
                        paginationPerPage={20}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        paginationComponentOptions={{
                            rowsPerPageText: 'Rows per page:'
                        }}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={data.length}
                        // expandableRows
                        selectableRows
                        customStyles={{
                            headRow: {
                                style: {
                                    backgroundColor: '#279e6c', // Green background
                                    color: '#fff', // White text
                                    textTransform: 'uppercase', // Uppercase text
                                    // fontSize: '10px', // Adjust font size for header row
                                    minHeight: '1.5rem',
                                    paddingTop: '8px',
                                    paddingBottom: '8px'
                                }
                            }
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default TransactionsTable;

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Tab, Tabs, Alert, Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';
import MessageReport from "./nvd3-chart1001"
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({
        customized: false,
        emails: [],
        category: '',
        to: [],
        clen: 0,
        title: '',
        message: '',
        image: '',
        scheduled: false,
        sdate: '',
        userName: account.user.firstname,
        userId: account.user.id
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedTag, setSelectedTag] = useState('');
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [doneOptions, setDoneOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'category') {
            setUserOptions(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
    };

    const handleInput = (name, value) => {
        setData((prevData) => {
            const newData = { ...prevData, [name]: value, clen: data?.to?.length || data?.emails?.length };

            // Clear emails and customized fields when category or to is changed
            if (name === 'category' || name === 'to') {
                newData.customized = false;
                newData.emails = '';
            }

            return newData;
        });
    };

    const handleEmailInput = (e) => {
        const value = e.target.value;
        // Split emails by commas and trim whitespace
        const emails = value.split(',').map((email) => email.trim());
        handleInput('emails', emails);
    };

    const handleGoat = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    console.log(data);

    const formData = new FormData();
    formData.append('customized', data.customized);
    if (data.customized) {
        formData.append('emails', JSON.stringify(data?.emails));
    } else {
        formData.append('category', data?.category);
        formData.append('to', JSON.stringify(data?.to));
    }
    formData.append('title', data.title);
    formData.append('message', data.message);
    formData.append('scheduled', data.scheduled);
    if (data.scheduled) {
        formData.append('sdate', data?.sdate);
    }
    formData.append('image', selectedImage || null);
    formData.append('userId', data.userId);
    formData.append('userName', data.userName);
    formData.append('clen', data.customized ? data?.emails?.length : data?.to?.length);

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }

        axios
            .post(`${apiUrl}/send-mails`, formData)
            .then((response) => {
                if (response.data) {
                    setSuccess(response.data?.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(response.data?.message);
                    formRef.current.reset();
                    onCancel();
                    setSelectedTag(null);
                    setShowConfirmationModal(false);
                    setData({
                        customized: false,
                        emails: [],
                        category: '',
                        to: [],
                        clen: 0,
                        title: '',
                        message: '',
                        image: '',
                        scheduled: false,
                        sdate: '',
                        userName: account.user.firstname,
                        userId: account.user.id
                    });
                }

                // console.log(response);
            })
            .catch((error) => {
                setError(error);
                toast.error(error.response?.data?.message);
                console.log(error.message);
                setShowConfirmationModal(false);
            });
    };

    const handleCancelConfirmation = () => {
        setShowConfirmationModal(false);
    };

    useEffect(() => {
        if (userOptions) {
            fetchSupplierOptions(userOptions);
            setSelectedTag('');
        }
    }, [userOptions]);

    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const fetchSupplierOptions = async (userOptions) => {
        try {
            let url;
            let labelKey;
            let valueKey;

            switch (userOptions) {
                case 'Vetenary':
                    url = `${apiUrl}/vetenary`;
                    labelKey = 'name';
                    valueKey = 'email';
                    break;
                case 'Customers':
                    url = `${apiUrl}/customer`;
                    labelKey = 'name';
                    valueKey = 'email';
                    break;
                case 'Suppliers':
                    url = `${apiUrl}/suppliers`;
                    labelKey = 'name';
                    valueKey = 'email';
                    break;
                case 'Employees':
                    url = `${apiUrl}/users`;
                    labelKey = `firstname`;
                    valueKey = `email`;
                    break;
                case 'Admin Users':
                    url = `${apiUrl}/admins`;
                    labelKey = `firstname`;
                    valueKey = `email`;
                    break;
                default:
                    url = null;
            }

            const response = await axios.get(url);
            const data = response?.data;
            const options = data?.map((item) => ({
                label: item[labelKey],
                value: item[valueKey]
            }));
            setDoneOptions(options);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const selectOptions = [
        // { value: 'All', label: 'All' },
        { value: 'Employees', label: 'Employees' },
        { value: 'Admin Users', label: 'Admin Users' },
        { value: 'Customers', label: 'Customers' },
        { value: 'Suppliers', label: 'Suppliers' },
        { value: 'Vetenary', label: 'Vetenary' }
    ];

    const handleCheckboxChange = (field, checked) => {
        setData((prevData) => {
            const newData = { ...prevData, [field]: checked, clen: data?.to?.length || data?.emails?.length };

            if (field === 'customized' && checked === true) {
                newData.category = null;
                newData.to = [];
            } else if (field === 'scheduled' && checked === false) {
                newData.sdate = null;
            } else if (field === 'customized' && checked === false) {
                newData.emails = null;
            }

            return newData;
        });
    };

    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ]
    };

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video'
    ];

    return (
        <React.Fragment>
             <Tabs defaultActiveKey="timeline">
                            <Tab eventKey="timeline" title="MESSAGE" className="mt-4">
            <Row>
                <Col>
                    <Card title="Send Email Messages " isOption>
                        <Form onSubmit={handleGoat} ref={formRef}>
                            <Row>
                                <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicAddress">
                                    <Form.Label>Customize</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md={9} sm={12} lg={9} controlId="formBasicAddress">
                                    <Form.Check
                                        onChange={(e) => handleCheckboxChange('customized', e.target.checked)}
                                        type="checkbox"
                                        name="customized"
                                    />
                                </Form.Group>
                                {data?.customized ? (
                                    <>
                                        <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicTel">
                                            <Form.Label>Emails</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md={9} sm={12} lg={9} controlId="formBasicTel">
                                            <Form.Control
                                                // size='lg'
                                                required
                                                onChange={handleEmailInput}
                                                type="text"
                                                name="emails"
                                                placeholder="Enter email addresses, separated by commas"
                                            />
                                            <Form.Text id="passwordHelpBlock" muted>
                                                Enter email addresses, separated by commas
                                            </Form.Text>
                                        </Form.Group>
                                    </>
                                ) : (
                                    <>
                                        <Form.Group as={Col} md={3} sm={12} lg={3}>
                                            <Form.Label>Category</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md={9} sm={12} lg={9}>
                                            <Select
                                                options={selectOptions}
                                                onChange={(selectedOption) => handleInpute('category', selectedOption.value || null)}
                                                isSearchable
                                                // isClearable
                                                name="category"
                                                placeholder="-- Choose from the category to send email to... --"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicName">
                                            <Form.Label>Choose</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md={9} sm={12} lg={9} controlId="formBasicName">
                                            {userOptions && userOptions !== 'All' ? (
                                                <Select
                                                    value={selectedTag}
                                                    onChange={(selectedOption) =>
                                                        setSelectedTag(
                                                            handleInput(
                                                                'to',
                                                                selectedOption.map((option) => option.value)
                                                            )
                                                        )
                                                    }
                                                    options={doneOptions}
                                                    isSearchable
                                                    isMulti
                                                    name="to"
                                                    placeholder="-- Select Reciever --"
                                                    required
                                                    as="textarea"
                                                />
                                            ) : (
                                                <Form.Text id="toCheck" muted>
                                                    You have selected 'All' and everyone will recieve an email
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </>
                                )}
                                <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicTel">
                                    <Form.Label>Length</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md={9} sm={12} lg={9} controlId="formBasicTel">
                                    <Form.Control
                                        required
                                        onChange={(e) => handleInput('clen', e.target.value)}
                                        value={data?.to?.length || data?.emails?.length}
                                        type="text"
                                        name="clen"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicTel">
                                    <Form.Label>Title/Subject</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md={9} sm={12} lg={9} controlId="formBasicTel">
                                    <Form.Control
                                        required
                                        onChange={(e) => handleInput('title', e.target.value)}
                                        type="text"
                                        name="title"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicAddress">
                                    <Form.Label>Compose</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md={9} sm={12} lg={9} controlId="formBasicAddress">
                                    <Form.Control
                                        as="textarea"
                                        rows={7}
                                        required
                                        onChange={(e) => handleInput('message', e.target.value)}
                                        name="message"
                                    />
                                    {/* <ReactQuill
                                        //   value={message}
                                        onChange={(content, delta, source, editor) => handleInput('message', editor.getHTML())}
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        required
                                    /> */}
                                </Form.Group>
                                <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicAddress">
                                    <Form.Label>Attachment</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md={9} sm={12} lg={9} controlId="formBasicAddress">
                                    <Form.File
                                        // required
                                        onChange={handleImageChange}
                                        // type="textarea"
                                        name="image"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicAddress">
                                    <Form.Label>Schedule</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md={9} sm={12} lg={9} controlId="formBasicAddress">
                                    <Form.Check
                                        // required
                                        onChange={(e) => handleCheckboxChange('scheduled', e.target.checked)}
                                        type="checkbox"
                                        name="scheduled"
                                    />
                                </Form.Group>
                                {data?.scheduled && (
                                    <>
                                        <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicAddress">
                                            <Form.Label>Schedule Time</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md={9} sm={12} lg={9} controlId="formBasicAddress">
                                            <Form.Control
                                                required
                                                onChange={(e) => handleInput('sdate', e.target.value)}
                                                type="datetime-local"
                                                name="sdate"
                                            />
                                        </Form.Group>
                                    </>
                                )}
                            </Row>
                            <Modal.Footer>
                                {createPermission && (
                                    <Button type="submit">
                                        <span className="feather icon-mail"> </span>Send
                                    </Button>
                                )}
                                <Button variant="secondary" onClick={onCancel}>
                                    <span className="feather icon-x"> </span>Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Card>
                </Col>
            </Row>
</Tab>
<Tab eventKey="direct" title="REPORT" className="mt-4">
                    <MessageReport />
                </Tab>
            </Tabs>
            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;

const menuItems = {
    items: [
        {
            id: 'navigation',
            title: 'Home',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/app/dashboard',
                    icon: 'feather icon-home'
                }
            ]
        },
        {
            id: 'calender',
            title: 'Calendar Management',
            type: 'group',
            icon: 'icon-calendar',

            children: [
                // {
                //     id: 'calendar',
                //     title: 'Events & Tasks',
                //     type: 'item',
                //     url: '/calendar/events',
                //     icon: 'feather icon-calendar',

                // },
                {
                    id: 'calendar',
                    title: 'Calendar',
                    type: 'item',
                    url: '/calendar',
                    icon: 'feather icon-calendar'
                }
            ]
        },
        {
            id: 'communication',
            title: 'Communication Management',
            type: 'group',
            icon: 'icon-message-circle',

            children: [
                {
                    id: 'communication',
                    title: 'Messaging',
                    type: 'item',
                    url: '/communication/create',
                    icon: 'feather icon-message-circle'
                }
                // {
                //     id: 'communication',
                //     title: 'Messaging Report',
                //     type: 'item',
                //     url: '/communication/report',
                //     icon: 'feather icon-message-circle',

                // }
            ]
        },
        {
            id: 'animals-management',
            title: 'Animal Management',
            type: 'group',
            icon: 'icon-ui',

            children: [
                {
                    id: 'poultry',
                    title: 'Poultry',
                    type: 'collapse',
                    icon: 'feather icon-sliders',

                    children: [
                        {
                            id: 'poultry',
                            title: 'Poultry',
                            type: 'item',
                            url: '/poultry/poultry'
                        },
                        {
                            id: 'poultry',
                            title: 'Add Poultry',
                            type: 'item',
                            url: '/poultry/new-poultry'
                        },
                        {
                            id: 'poultry',
                            title: 'Poultry Update',
                            type: 'item',
                            url: '/poultry/poultry-updates'
                        },
                        {
                            id: 'poultry',
                            title: 'Eggs Updates',
                            type: 'item',
                            url: '/poultry/eggs-updates'
                        },
                        {
                            id: 'poultry',
                            title: 'Sell Eggs',
                            type: 'item',
                            url: '/poultry/sell-eggs'
                        },
                        {
                            id: 'poultry',
                            title: 'Sell Broken Eggs',
                            type: 'item',
                            url: '/poultry/sell-broken-eggs'
                        },
                        {
                            id: 'poultry',
                            title: 'Sell Poultry',
                            type: 'item',
                            url: '/poultry/sell-poultry'
                        },
                        {
                            id: 'poultry',
                            title: 'Poultry Vaccination',
                            type: 'item',
                            url: '/poultry/vaccination'
                        },
                        {
                            id: 'poultry',
                            title: 'Poultry Feeds',
                            type: 'item',
                            url: '/poultry/feeds-consumption'
                        },
                        {
                            id: 'poultry',
                            title: 'Poultry Report',
                            type: 'item',
                            url: '/poultry/report'
                        }
                    ]
                },
                {
                    id: 'pigs',
                    title: 'Pigs',
                    type: 'collapse',
                    icon: 'feather icon-sliders',

                    children: [
                        {
                            id: 'pigs',
                            title: 'Pigs',
                            type: 'item',
                            url: '/pigs/pigs'
                        },
                        {
                            id: 'pigs',
                            title: 'Add Pigs',
                            type: 'item',
                            url: '/pigs/add-pig'
                        },
                        {
                            id: 'pigs',
                            title: 'Pig Update',
                            type: 'item',
                            url: '/pigs/pig-update'
                        },
                        {
                            id: 'pigs',
                            title: 'Piglets Sired',
                            type: 'item',
                            url: '/pigs/piglets-sired'
                        },
                        {
                            id: 'pigs',
                            title: 'Piglets Management',
                            type: 'item',
                            url: '/pigs/piglets-management'
                        },
                        {
                            id: 'pigs',
                            title: 'Sell Pigs',
                            type: 'item',
                            url: '/pigs/sell-pigs'
                        },
                        {
                            id: 'pigs',
                            title: 'Pigs Vaccination',
                            type: 'item',
                            url: '/pigs/vaccination'
                        },
                        {
                            id: 'pigs',
                            title: 'Pig Feeds',
                            type: 'item',
                            url: '/pigs/feeds-consumption'
                        },
                        {
                            id: 'pigs',
                            title: 'Pigs Report',
                            type: 'item',
                            url: '/pigs/feeds-consumption'
                        }
                    ]
                },
                {
                    id: 'goats',
                    title: 'Goats',
                    type: 'collapse',
                    icon: 'feather icon-sliders',

                    children: [
                        {
                            id: 'goats',
                            title: 'Goats',
                            type: 'item',
                            url: '/goats/goats'
                        },
                        {
                            id: 'goats',
                            title: 'Add Goats',
                            type: 'item',
                            url: '/goats/new-goats'
                        },
                        {
                            id: 'goats',
                            title: 'Goats Update',
                            type: 'item',
                            url: '/goats/goats-update'
                        },
                        {
                            id: 'goats',
                            title: 'Goats Sired',
                            type: 'item',
                            url: '/goats/goats-sired'
                        },
                        {
                            id: 'goats',
                            title: 'Sell Goats',
                            type: 'item',
                            url: '/goats/sell-goats'
                        },
                        {
                            id: 'goats',
                            title: 'Goats Vaccination',
                            type: 'item',
                            url: '/goats/vaccination'
                        },
                        {
                            id: 'goats',
                            title: 'Goat Feeds',
                            type: 'item',
                            url: '/goats/feeds-consumption'
                        }
                    ]
                },
                {
                    id: 'vaccination',
                    title: 'Emergency Vaccination',
                    type: 'item',
                    icon: 'feather icon-sliders',
                    url: '/vaccination/emergency-vaccination'
                }
            ]
        },
        {
            id: 'stock',
            title: 'Stock Management',
            type: 'group',
            icon: 'icon-ui',

            children: [
                {
                    id: 'feeds',
                    title: 'Feeds',
                    type: 'collapse',
                    icon: 'feather icon-globe',

                    children: [
                        {
                            id: 'feeds',
                            title: 'Feeds',
                            type: 'item',
                            url: '/feeds'
                        },
                        {
                            id: 'feeds',
                            title: 'Add Feeds',
                            type: 'item',
                            url: '/feeds/new-feed'
                        },
                        {
                            id: 'feeds',
                            title: 'Feeds Restock',
                            type: 'item',
                            url: '/feeds/restock'
                        }
                    ]
                },
                {
                    id: 'vaccine',
                    title: 'Vaccines',
                    type: 'collapse',
                    icon: 'feather icon-globe',

                    children: [
                        {
                            id: 'vaccine',
                            title: 'Vaccines',
                            type: 'item',
                            url: '/vaccines'
                        },
                        {
                            id: 'vaccine',
                            title: 'Add Vaccines',
                            type: 'item',
                            url: '/vaccine/add-vaccine'
                        },

                        {
                            id: 'vaccine',
                            title: 'Vaccines Restock',
                            type: 'item',
                            url: '/vaccine/restock'
                        }
                    ]
                }
                // {
                //     id: 'vaccination',
                //     title: 'Vaccination',
                //     type: 'item',
                //     icon: 'feather icon-move',
                //     url: '/expense/vaccination',
                // }
            ]
        },
        {
            id: 'stakeholders',
            title: 'Stakeholder Management',

            type: 'group',
            icon: 'icon-group',
            children: [
                {
                    id: 'add-stakeholders',
                    title: 'Stakeholders',
                    type: 'item',
                    url: '/stakeholders',
                    icon: 'feather icon-image'
                }
                // {
                //     id: 'add-customer',
                //     title: 'Customers',
                //     type: 'item',
                //     url: '/customers',
                //     icon: 'feather icon-image',

                // },
                // {
                //     id: 'add-vetenary',
                //     title: 'Vetenary',
                //     type: 'item',
                //     url: '/vetenary',
                //     icon: 'feather icon-image',

                // }
            ]
        },

        {
            id: 'farm',
            title: 'Farms Management',
            type: 'group',
            icon: 'icon-group',

            children: [
                {
                    id: 'farms',
                    title: 'Farms',
                    type: 'collapse',
                    icon: 'feather icon-map',

                    children: [
                        {
                            id: 'farms',
                            title: 'Farms',
                            type: 'item',
                            url: '/farms'
                        },
                        {
                            id: 'farms',
                            title: 'Assets',
                            type: 'item',
                            url: '/farms/assets'
                        },
                        {
                            id: 'farms',
                            title: 'Manage Farms',
                            type: 'item',
                            url: '/farms/manage'
                        }
                    ]
                }
                // {
                //     id: 'assets',
                //     title: 'Farms Assets',

                //     type: 'collapse',
                //     icon: 'feather icon-settings',
                //     children: [
                //         {
                //             id: 'assets',
                //             title: 'Assets',
                //             type: 'item',
                //             url: '/assets',

                //         }
                //         // {
                //         //     id: 'farms',
                //         //     title: 'Create New',
                //         //     type: 'item',
                //         //     url: '/farms/new-farm',
                //         // },
                //         // {
                //         //     id: 'assets',
                //         //     title: 'Assets Maintanance',
                //         //     type: 'item',
                //         //     url: '/assets/maintanance',
                //         // },
                //     ]
                // }
            ]
        },
        {
            id: 'admins',
            title: 'Users Management',

            type: 'group',
            icon: 'icon-group',

            children: [
                {
                    id: 'admins',
                    title: 'Users',
                    type: 'collapse',
                    icon: 'feather icon-users',

                    children: [
                        {
                            id: 'users',
                            title: 'Employees',
                            type: 'item',
                            url: '/users/employees'
                        },
                        {
                            id: 'admins',
                            title: 'Admins',
                            type: 'item',
                            url: '/users/admins'
                        },
                        {
                            id: 'admins',
                            title: 'Roles',
                            type: 'item',
                            url: '/users/roles'
                        }
                    ]
                },
                {
                    id: 'users',
                    title: 'Payroll',
                    type: 'item',
                    icon: 'feather icon-users',
                    url: '/users/payroll'
                },
                {
                    id: 'users',
                    title: 'Stock',
                    type: 'item',
                    icon: 'feather icon-users',
                    url: '/users/stock'
                }
                // {
                //     id: 'food',
                //     title: 'Food',
                //     type: 'collapse',
                //     icon: 'feather icon-users',

                //     children: [
                //         {
                //             id: 'food',
                //             title: 'Food',
                //             type: 'item',
                //             url: '/food',

                //         },
                //         {
                //             id: 'food',
                //             title: 'Purchase Food',
                //             type: 'item',
                //             url: '/food/add-food',

                //         }
                //         // {
                //         //     id: 'food',
                //         //     title: 'Restock',
                //         //     type: 'item',
                //         //     url: '/food/restock',
                //         // }
                //     ]
                // },
                // {
                //     id: 'water',
                //     title: 'Water',
                //     type: 'collapse',
                //     icon: 'feather icon-umbrella',

                //     children: [
                //         {
                //             id: 'water',
                //             title: 'Water',
                //             type: 'item',
                //             url: '/water',

                //         },
                //         {
                //             id: 'water',
                //             title: 'Purchase Water',
                //             type: 'item',
                //             url: '/water/water',

                //         }
                //         // {
                //         //     id: 'water',
                //         //     title: 'Refill Water',
                //         //     type: 'item',
                //         //     url: '/water/water-refill',
                //         // }
                //     ]
                // }
            ]
        },
        {
            id: 'report',
            title: 'Report Management',
            type: 'group',
            icon: 'icon-group',

            children: [
                {
                    id: 'overview',
                    title: 'Business Overview',
                    type: 'collapse',
                    icon: 'feather icon-bar-chart-2',
                    children: [
                        {
                            id: 'overview',
                            title: 'Overview',
                            type: 'item',
                            url: '/overview'
                        },
                        {
                            id: 'overview',
                            title: 'Profit & Loss',
                            type: 'item',
                            url: '/profit-loss'
                        },
                        // {
                        //     id: 'overview',
                        //     title: 'Cash Flow',
                        //     type: 'item',
                        //     url: '/cash-flow'
                        // },
                        {
                            id: 'overview',
                            title: 'Balance Sheet',
                            type: 'item',
                            url: '/balance-sheet'
                        },
                        // {
                        //     id: 'overview',
                        //     title: 'Performance Ratio',
                        //     type: 'item',
                        //     url: '/perfomance'
                        // }
                    ]
                },
                {
                    id: 'sales',
                    title: 'Sales',
                    type: 'collapse',
                    icon: 'feather icon-bar-chart-2',

                    children: [
                        {
                            id: 'sales',
                            title: 'All Sales',
                            type: 'item',
                            url: '/transaction/all-sales'
                        },
                        {
                            id: 'sales',
                            title: 'View Sales',
                            type: 'item',
                            url: '/transaction/sales-summary'
                        }
                    ]
                },
                {
                    id: 'reports',
                    title: 'Purchases & Expenses',
                    type: 'collapse',
                    icon: 'feather icon-bar-chart-2',
                    children: [
                        {
                            id: 'expense',
                            title: 'Expenses',
                            type: 'item',
                            url: '/reports/genaral-expenses'
                        },
                        // {
                        //     id: 'reports',
                        //     title: 'Direct Expenses',
                        //     type: 'item',
                        //     url: '/reports/direct-expenses',

                        // },
                        {
                            id: 'expense',
                            title: 'Stock Report',
                            type: 'item',
                            url: '/reports/stock'
                        },
                        // {
                        //     id: 'reports',
                        //     title: 'Animal Activity',
                        //     type: 'item',
                        //     url: '/reports/production',
                        // },
                        {
                            id: 'expense',
                            title: 'Animals Acquisation',
                            type: 'item',
                            url: '/reports/animal-acquisition'
                        }
                    ]
                },
                {
                    id: 'invoices',
                    title: 'Invoices',
                    type: 'item',
                    icon: 'feather icon-bar-chart-2',
                    url: '/invoices'
                },
                {
                    id: 'invoices',
                    title: 'Invoicess',
                    icon: 'feather icon-bar-chart-2',
                    type: 'collapse',

                    children: [
                        {
                            id: 'invoice',
                            title: 'All Invoices',
                            type: 'item',
                            url: '/invoices/all-invoices',

                        },
                    ]
                },
                {
                    id: 'accounts',
                    title: 'Accountant',
                    icon: 'feather icon-bar-chart-2',
                    type: 'collapse',

                    children: [
                        {
                            id: 'transaction',
                            title: 'All Transactions',
                            type: 'item',
                            url: '/accounts/all-transactions',

                        },
                        {
                            id: 'transactions',
                            title: 'Make Transaction',
                            type: 'item',
                            url: '/accounts/new-transaction'
                        },
                        {
                            id: 'transaction',
                            title: 'Manage Loans',
                            type: 'item',
                            url: '/accounts/loans'
                        },
                        {
                            id: 'transaction',
                            title: 'Payments',
                            type: 'item',
                            url: '/accounts/payments'
                        },
                        {
                            id: 'transactions',
                            title: 'Account Transfer',
                            type: 'item',
                            url: '/accounts/transfer'
                        },

                        
                        {
                            id: 'transaction',
                            title: 'Chart of Accounts',
                            type: 'item',
                            url: '/accounts'
                        },
                        {
                            id: 'transaction',
                            title: 'Accounts Statement',
                            type: 'item',
                            url: '/accounts/statement'
                        }
                    ]
                },
                // {
                //     id: 'transactions',
                //     title: 'All Transactions',
                //     type: 'item',
                //     icon: 'feather icon-bar-chart-2',
                //     url: '/transactions'
                // },
                {
                    id: 'admins',
                    title: 'Logs',
                    type: 'item',
                    icon: 'feather icon-bar-chart-2',
                    url: '/logs'
                }
            ]
        }
    ]
};

export default menuItems;

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Row, Col, Form, Button, Table, Alert, Tabs, Tab, Modal, Card } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, PDFViewer } from '@react-pdf/renderer';
// import Card from '../../components/Card/MainCard';
import config from '../../config';
import Receipt from './GeneralReceipt01.js';
import { Link } from 'react-router-dom';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal.js';
import DataTable from 'react-data-table-component';
import { PDFContent } from '../../components/Receipts/index.js';
import { Bar } from 'react-chartjs-2';
// import 'chart.js/auto';
import { subMonths, startOfMonth, endOfMonth, format } from 'date-fns';

const TransactionHistory = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [transactionData, setTransactionData] = useState([]);
    const [showReceipt, setShowReceipt] = useState(false);
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const [transactionId, setTransactionId] = useState(null);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [receiptData, setReceiptData] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationData, setConfirmationData] = useState({ id: null, description: null });
    const [searchText, setSearchText] = useState('');
    const [chartData, setChartData] = useState(null);
    const [totalSales, setTotalSales] = useState(0);
    const [averageSales, setAverageSales] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('3m'); // Default tab: 3 months

    // useEffect(() => {
    //     const firstDayOfMonth = format(startOfMonth(new Date()), "yyyy-MM-dd");
    //     const lastDayOfMonth = format(endOfMonth(new Date()), "yyyy-MM-dd");

    //     setStartDate(firstDayOfMonth);
    //     setEndDate(lastDayOfMonth);
    // }, []);

    useEffect(() => {
        setTabDateRange('3m'); // Set the default range to 3 months
    }, []);

    const setTabDateRange = (range) => {
        const today = new Date();
        let start, end;

        switch (range) {
            case '3m':
                start = subMonths(today, 3);
                end = today;
                break;
            case '6m':
                start = subMonths(today, 6);
                end = today;
                break;
            case '1y':
                start = subMonths(today, 12); // 1 year ago
                end = today;
                break;
            case '2y':
                start = subMonths(today, 24); // 2 years ago
                end = today;
                break;
            default:
                start = startOfMonth(today); // Fallback: Start of the current month
                end = endOfMonth(today); // End of the current month
        }

        setStartDate(format(start, 'yyyy-MM-dd'));
        setEndDate(format(end, 'yyyy-MM-dd'));
        setActiveTab(range);
    };

    const handleModal = async (transactionId) => {
        setSelectedTransaction(transactionId);
        setShowReceiptModal(true);
        setShowReceipt(true);
        setTransactionId(transactionId);
    };

    const handleDownloadButtonClick = (fileUrl) => {
        axios
            .get(fileUrl, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileUrl.split('/').pop());
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                // console.error('Error downloading file:', error);
                toast.error('Error downloading file.');
            });
    };

    useEffect(() => {
        const fetchTransactionData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/all-summary-receipts/${transactionId}`);
                const { receiptData } = response.data;
                setReceiptData(receiptData[0]);
            } catch (error) {
                console.error('Error retrieving receipt data:', error);
            }
        };

        fetchTransactionData();
    }, [transactionId, apiUrl]);

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${apiUrl}/all-transaction-history`, {
                params: {
                    startDate: startDate, // Example start date
                    endDate: endDate // Example end date
                }
            });
            setTransactionData(response.data);
            processChartData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching transaction history:', error);
            toast.error('Error fetching transaction history.');
            setLoading(false);
        }
    };

    const handleCloseReceiptModal = () => {
        setShowReceiptModal(false);
        // window.location.reload();
    };

    const getLabelColorClass = (description) => {
        switch (description) {
            case 'Goats Sales':
                return 'label-orange';
            case 'Eggs Sales':
                return 'label-blue';
            case 'Pigs Sales':
                return 'label-till';
            case 'Poultry Sales':
                return 'label-brown';
            case 'Broken Eggs':
                return 'label-kink';
            default:
                return '';
        }
    };

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [
            ['Description', 'TransactionID', 'Customer', 'Amount', 'Payment Mode', 'Reference', 'Date/Time', 'Generated By', 'Farm'],
            ...transactionData.map((item) => [
                item.description,
                item.transactionId,
                item.cus_name,
                item.amount,
                item.pay_method,
                item.pay_reference,
                item.createdAt,
                item.servedBy,
                item.farm
            ])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [
                ['Description', 'TransactionID', 'Customer', 'Amount', 'Payment Mode', 'Reference', 'Date/Time', 'Generated By', 'Farm']
            ],
            body: transactionData.map((user) => [
                user.description,
                user.transactionId,
                user.cus_name,
                user.amount.toLocaleString(),
                user.pay_method,
                user.pay_reference,
                user.createdAt.toLocaleString(),
                user.servedBy,
                user.farm
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const handleViewFile = (image) => {
        window.open(`${apiUrl}/uploads/${image}`, '_blank');
    };

    const handleArchive = (id, description) => {
        setConfirmationData({ id, description });
        setShowConfirmationModal(true);
        console.log(id, description);
    };

    const handleConfirmation = async () => {
        const { id, description } = confirmationData;

        axios
            .post(`${apiUrl}/archive-sales/${id}/${description}`)
            .then(() => {
                // Update the frontend state to reflect the archived status
                setTransactionData((prevData) =>
                    prevData.map((transaction) => {
                        if (transaction.id === id) {
                            return { ...transaction, archived: true };
                        }
                        return transaction;
                    })
                );
                toast.success('Sales Transaction archived successfully.');
                setShowConfirmationModal(false);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.error) {
                    // Display the error message to the user
                    toast.error(error.response.data.error);
                    setShowConfirmationModal(false);
                } else {
                    // Handle other types of errors
                    console.error('Error archiving transaction:', error);
                    toast.error('Error archiving transaction.');
                    setShowConfirmationModal(false);
                }
            });
    };

    const handleCancelConfirmation = () => {
        setShowConfirmationModal(false);
    };

    const columns = [
        {
            name: 'Description',
            selector: (row) => <div className={getLabelColorClass(row.description)}>{row.description}</div>,
            sortable: true,
            wrap: true,
            minWidth: '100px',
            maxWidth: '200px'
        },
        { name: 'Transaction ID', selector: (row) => row.transactionId, sortable: true, wrap: true, minWidth: '100px', maxWidth: '200px' },
        { name: 'Customer', selector: (row) => row.cus_name, sortable: true, wrap: true, minWidth: '120px', maxWidth: '220px' },
        {
            name: 'Amount',
            selector: (row) => row.amount,
            cell: (row) =>
                row.amount !== null
                    ? row.amount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                      })
                    : '',
            sortable: true,
            wrap: true,
            minWidth: '100px',
            maxWidth: '200px'
        },
        { name: 'Mode', selector: (row) => row.pay_method, sortable: true, wrap: true, minWidth: '100px', maxWidth: '200px' },
        {
            name: 'Reference',
            selector: (row) => row.pay_reference,
            sortable: true,
            wrap: true,
            minWidth: '100px',
            maxWidth: '200px'
        },
        {
            name: 'Timestamp',
            selector: (row) => new Date(row.createdAt).toLocaleString(),
            sortable: true,
            wrap: true,
            minWidth: '180px',
            maxWidth: '220px'
        },
        // { name: 'Generated By', selector: (row) => row.servedBy, sortable: true, wrap: true },
        // { name: 'Farm', selector: (row) => row.farm, sortable: true, wrap: true },
        {
            name: 'Receipt',
            cell: (row) => (
                <div>
                    <Button onClick={() => handleModal(row.transactionId)}>
                        <i className="feather icon-eye" />
                    </Button>
                    {row.image && (
                        <Button onClick={() => handleViewFile(row.image)}>
                            <i className="feather icon-download-cloud" /> View
                        </Button>
                    )}
                    {row.archive === 1 ? (
                        <Button variant="secondary">
                            <i className="feather icon-slash" />
                            {/* Archived */}
                        </Button>
                    ) : (
                        <Button variant="danger" onClick={() => handleArchive(row.id, row.description)}>
                            <i className="feather icon-x-square" />
                            {/* Archive */}
                        </Button>
                    )}
                </div>
            ),
            minWidth: '200px',
            maxWidth: '300px'
        }
    ];

    const filteredData = transactionData.filter((item) => {
        // Check if the item matches the search text
        const matchesSearchText =
            !searchText ||
            Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()));

        return matchesSearchText;
        // return matchesSearchText && matchesStatusFilter && matchesEventTypeFilter;
    });

    const processChartData = (data) => {
        const monthlyData = {};
        let total = 0;

        // Aggregate data by month and description
        data.forEach((item) => {
            const month = new Date(item.recorded).toLocaleString('default', {
                month: 'short',
                year: 'numeric'
            });
            const description = item.description;

            if (!monthlyData[month]) monthlyData[month] = {};
            if (!monthlyData[month][description]) monthlyData[month][description] = 0;

            monthlyData[month][description] += item.amount;
            total += item.amount;
        });

        // Calculate metrics
        const months = Object.keys(monthlyData).length;
        setTotalSales(total);
        setAverageSales(months > 0 ? (total / months).toFixed(2) : 0);

        // Prepare chart data
        const monthsLabels = Object.keys(monthlyData);
        const descriptions = [...new Set(data.map((item) => item.description))];

        const datasets = descriptions.map((desc) => ({
            label: desc,
            data: monthsLabels.map((month) => monthlyData[month][desc] || 0),
            backgroundColor: getRandomColor()
        }));

        setChartData({
            labels: monthsLabels,
            datasets: datasets
        });
    };

    const calculateTotals = (data) => {
        const totals = {
            'Goats Sales': 0,
            'Eggs Sales': 0,
            'Broken Eggs': 0,
            'Pigs Sales': 0,
            'Poultry Sales': 0
        };

        data.forEach((item) => {
            totals[item.description] += item.amount || 0;
        });

        return totals;
    };

    const totals = calculateTotals(filteredData);

    const getTopCategory = (totals) => {
        return Object.entries(totals).reduce((top, current) => {
            return current[1] > top[1] ? current : top;
        });
    };

    const [topCategory, topAmount] = getTopCategory(totals);

    const getTransactionCounts = (data) => {
        const monthlyCounts = {};

        data.forEach((item) => {
            const month = new Date(item.recorded || item.createdAt).toLocaleString('default', {
                month: 'short',
                year: 'numeric'
            });

            monthlyCounts[month] = (monthlyCounts[month] || 0) + 1;
        });

        return monthlyCounts;
    };

    const monthlyTransactionCounts = getTransactionCounts(filteredData);

    const calculateAverageTransaction = (data) => {
        const categoryTotals = {};
        const categoryCounts = {};

        data.forEach((item) => {
            categoryTotals[item.description] = (categoryTotals[item.description] || 0) + item.amount;
            categoryCounts[item.description] = (categoryCounts[item.description] || 0) + 1;
        });

        const averages = {};
        for (const category in categoryTotals) {
            averages[category] = categoryTotals[category] / categoryCounts[category];
        }

        return averages;
    };

    const averages = calculateAverageTransaction(filteredData);

    const getTotalTransactionsAndRevenue = (data) => {
        return {
            totalTransactions: data.length,
            totalRevenue: data.reduce((sum, item) => sum + item.amount, 0)
        };
    };

    const { totalTransactions, totalRevenue } = getTotalTransactionsAndRevenue(filteredData);

    const getRandomColor = () =>
        `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`;

    return (
        <React.Fragment>
            <Col sm={12}>
                {success && (
                    <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                        {success}
                    </Alert>
                )}
                {error && (
                    <Alert variant="danger" onClose={() => setError(null)} dismissible>
                        {error}
                    </Alert>
                )}
                <ToastContainer />
            </Col>
                 <Card.Text className="align-items-center">
                                <div>
                                    <span className="pr-3">Filters:</span>
                                    {['3m', '6m', '1y', '2y'].map((range) => (
                                        <Button
                                            key={range}
                                            onClick={() => setTabDateRange(range)}
                                            style={{
                                                marginRight: '5px',
                                                padding: '5px',
                                                backgroundColor: activeTab === range ? '#007bff' : '#f8f9fa',
                                                color: activeTab === range ? 'white' : 'black',
                                                border: activeTab === range ? 'none' : '1px solid #ced4da',
                                                borderRadius: '5px',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {range === '3m'
                                                ? 'Last 3 Months'
                                                : range === '6m'
                                                ? 'Last 6 Months'
                                                : range === '1y'
                                                ? 'Last 1 Year'
                                                : 'Last 2 Years'}
                                        </Button>
                                    ))}
                                    {/* </div>
                                            <div> */}
                                    <span className="px-3">Date Range:</span>
                                    <Form.Label>
                                        From: <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                    </Form.Label>
                                    <Form.Label style={{ marginLeft: '20px' }}>
                                        To: <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                    </Form.Label>
                
                                    <span className="px-3">Exports:</span>
                                </div>
                                <hr />
                            </Card.Text>
            <Row className="align-items-stretch mb-4">
                <Col xs={12} sm={12} lg={8} md={12}>
                    <Card className="h-89" title="Sales Metrics" isOption>
                        <div className='p-4'>

                        
                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p style={{ color: 'red' }}>{error}</p>
                        ) : chartData ? (
                            <Bar 
                            style={{ maxHeight: '380px' }}
                                data={chartData}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: 'Sales Metrics by Month'
                                        }
                                    }
                                }}
                            />
                        ) : (
                            <p>No data available</p>
                        )}</div>
                    </Card>
                </Col>
                <Col xs={12} sm={12} lg={4} md={12}>
                    <Row className="align-items-stretch mb-2">
                        <Col sm={12} lg={12} md={6}>
                            <Col sm={12} lg={12} md={6}>
                                
                                <Card>
                                    <Card.Body>
                                        <h6>Total Sales Summary</h6>
                                        <ul>
                                            {Object.entries(totals).map(([category, amount]) => (
                                                <li key={category}>
                                                    {category}: KES {amount.toFixed(2)}
                                                </li>
                                            ))}
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} lg={12} md={6}>
                                
                                <Card>
                                    <Card.Body>
                                        <h6>Average Transaction Value</h6>
                                        <ul>
                                            {Object.entries(averages).map(([category, avg]) => (
                                                <li key={category}>
                                                    {category}: KES {avg.toFixed(2)}
                                                </li>
                                            ))}
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={12} lg={12} md={12}>
                    <Row className="align-items-stretch mt-4">
                            <Col sm={12} lg={6} md={6}>
                            <Card>
                                    <Card.Body>
                                        <h6>Overall Metrics</h6>
                                        <p>Total Transactions: {totalTransactions}</p>
                                        <h6>Revenue</h6>
                                        <p>Total Revenue: KES {totalRevenue.toFixed(2)}</p>
                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col sm={12} lg={6} md={6}>
                            <Card>
                                    <Card.Body>
                                        <h6>Top Performing Category</h6>
                                        <p>
                                            {topCategory}: KES {topAmount.toFixed(2)}
                                        </p>
                                        <h6>Average Sales Per Month:</h6>
                                        <p>KES {averageSales.toLocaleString()}</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card style={{}}>
                        <Card.Body className="">
                            <Row>
                                <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicName">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </Form.Group>
                                {/* <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicName">
                                    <Form.Control
                                        type="date"
                                        placeholder="From..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </Form.Group> */}
                                {filteredData && filteredData.length > 0 && (
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicName">
                                        <Button onClick={exportToCSV}>Export as CSV</Button>
                                        <a ref={csvLinkRef} style={{ display: 'none' }} />
                                        <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                            Export PDF
                                        </a>
                                    </Form.Group>
                                )}
                            </Row>
                            <Row>
                                <Col>
                                    <DataTable
                                        // title="Combined Email Logs"
                                        columns={columns}
                                        data={filteredData}
                                        // expandableRows
                                        // expandableRowsComponent={ExpandableComponent}
                                        pagination
                                        highlightOnHover
                                        striped
                                        // noHeader
                                        dense
                                        bordered
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Rows per page:'
                                        }}
                                        paginationResetDefaultPage={true}
                                        paginationTotalRows={transactionData.length}
                                        // expandableRows
                                        // selectableRows
                                        onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                                        customStyles={{
                                            headRow: {
                                                style: {
                                                    backgroundColor: '#279e6c', // Green background
                                                    color: '#fff', // White text
                                                    textTransform: 'uppercase', // Uppercase text
                                                    // fontSize: '10px', // Adjust font size for header row
                                                    minHeight: '1.5rem',
                                                    paddingTop: '8px',
                                                    paddingBottom: '8px'
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {showReceipt && (
                        <Modal show={showReceiptModal} onHide={handleCloseReceiptModal} size="lg" centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Receipt</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Receipt transactionId={transactionId} />
                            </Modal.Body>
                            <Modal.Footer>
                                {receiptData && receiptData.transactionId && (
                                    <PDFDownloadLink
                                        className="btn btn-success mr-2"
                                        document={<PDFContent id={receiptData?.id} transactionData={receiptData} />}
                                        fileName={`${receiptData?.transactionId}_${receiptData?.createdAt}.pdf`}
                                    >
                                        {({ blob, url, loading, error }) =>
                                            loading ? 'Loading...' : <span className="feather icon-printer"> Print</span>
                                        }
                                    </PDFDownloadLink>
                                )}
                                <Button variant="secondary" onClick={handleCloseReceiptModal}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </Col>
            </Row>
            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default TransactionHistory;

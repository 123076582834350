import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Table, Button, Alert, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import axios from 'axios';
import Select from 'react-select';
import Card from '../../components/Card/MainCard';
import { usePermissions } from '../../permissions';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [newAccount, setNewAccount] = useState({
        accountName: null,
        accountBalance: '',
        accountCategory: '',
        accountCode: '',
        description: '',
        type: '',
        accountNumber: '',
        farm: null
    });
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [selectedFarm, setSelectedFarm] = useState(null);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(`${apiUrl}/farm`);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [apiUrl, farmOptions]);

    const accountCategories = {
        Assets: {
            prefix: '300',
            subcategories: ['Other Asset', 'Other Current Asset', 'Cash', 'Bank', 'Fixed Asset', 'Stock', 'Payment Clearing']
        },
        Liability: {
            prefix: '500',
            subcategories: ['Other Current Liability', 'Credit Card', 'Long Term Liability', 'Other Liability']
        },
        Equity: {
            prefix: '400',
            subcategories: ['Equity']
        },
        Income: {
            prefix: '100',
            subcategories: ['Income', 'Other Income']
        },
        Expense: {
            prefix: '200',
            subcategories: ['Expense', 'Cost of Goods Sold', 'Other Expense']
        }
    };

    const handleAccountTypeSelect = (selectedOption) => {
        setNewAccount({ ...newAccount, type: selectedOption.value, accountName: '', accountCode: '' });
    };

    const handleAccountNameSelect = (selectedOption) => {
        const accountType = newAccount.type;
        const prefix = accountCategories[accountType].prefix;
        const subcategoryIndex = accountCategories[accountType].subcategories.indexOf(selectedOption.value) + 1;
        const generatedCode = `${prefix}${String(subcategoryIndex).padStart(3, '0')}`; // e.g., "300001"

        setNewAccount({
            ...newAccount,
            accountCategory: selectedOption.value,
            accountCode: generatedCode
        });
    };

    const handleInput = (event) => {
        setNewAccount((prevAccount) => ({
            ...prevAccount,
            [event.target.name]: event.target.value,
            userName: account.user.firstname,
            userId: account.user.id
        }));
    };

    // Generate options for the primary categories
    const accountTypeOptions = Object.keys(accountCategories).map((category) => ({
        value: category,
        label: category
    }));

    // Generate options for the subcategories based on the selected category
    const accountNameOptions = newAccount.type
        ? accountCategories[newAccount.type].subcategories.map((name) => ({
              value: name,
              label: name
          }))
        : [];

    const handleCreateAccount = (e) => {
        e.preventDefault();
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/accounts`, newAccount)
            .then((response) => {
                setSuccess(`${newAccount.description} Account successfully created`);
                toast.success(`${newAccount.description} Account successfully created`);
                onCancel();
                setNewAccount({
                    accountName: null,
                    accountNumber: '',
                    accountBalance: '',
                    accountCategory: '',
                    accountCode: '',
                    description: '',
                    type: null
                });
            })
            .catch((error) => {
                setError(error.response.data.error);
                console.error('Error creating account: ', error);
            });
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleCreateAccount}>
                                <Row>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Account Type*</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formAccountTypeSelect">
                                        <Select
                                            options={accountTypeOptions}
                                            value={accountTypeOptions.find((option) => option.value === newAccount.type)}
                                            onChange={handleAccountTypeSelect}
                                            placeholder="Select Account Type"
                                            isClearable
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formAccountName">
                                        <Form.Label>Account Category*</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formAccountNameSelect">
                                        <Select
                                            options={accountNameOptions}
                                            name="accountCategory"
                                            value={accountNameOptions.find((option) => option.value === newAccount.accountCategory)}
                                            onChange={handleAccountNameSelect}
                                            placeholder="Select Account Name"
                                            isDisabled={!newAccount.type} // Disable until category is selected
                                            isClearable
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Account Name*</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                                        <Form.Control
                                            required
                                            onChange={handleInput}
                                            name="description"
                                            type="text"
                                            disabled={!newAccount.type && !newAccount.accountCategory}
                                            value={newAccount.description}
                                        />
                                    </Form.Group>

                                    {newAccount.accountCategory === 'Bank' && (
                                        <>
                                            <Form.Group as={Col} md={4} sm={12} lg={4}>
                                                <Form.Label>Account Number</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} md={8} sm={12} lg={8}>
                                                <Form.Control
                                                    required
                                                    onChange={handleInput}
                                                    name="accountNumber"
                                                    type="number"
                                                    value={newAccount.accountNumber}
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formAccountCode">
                                        <Form.Label>Generated Code</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formAccountCodeDisplay">
                                        <Form.Control type="text" value={newAccount.accountCode} readOnly placeholder="Generated Code" />
                                    </Form.Group>
                                    {/* <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Account Code</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                                        <Form.Control
                                            required
                                            onChange={handleInput}
                                            name="accountCode"
                                            type="number"
                                            value={newAccount.accountCode}
                                        />
                                    </Form.Group> */}
                                    {/* <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Account Code</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                                        <Form.Control
                                            required
                                            onChange={handleInput}
                                            name="accountBalance"
                                            type="number"
                                            value={newAccount.accountBalance}
                                        />
                                    </Form.Group> */}
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default SamplePage;

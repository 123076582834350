import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import config from "../../../config";
import { addDays, endOfMonth, format } from "date-fns";

const InvoiceFormModal = ({show, onHide, transactionData}) => {
  const [recipientType, setRecipientType] = useState("");
  const [stakeholders, setStakeholders] = useState([]);
  const [stakes, setStakes] = useState([]);
  const [stakeholdersTrans, setStakeholdersTrans] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [terms, setTerms] = useState("Due on Receipt");
  const [customDuration, setCustomDuration] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState("INV000001");
  const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString().slice(0, 10));
  const [dueDate, setDueDate] = useState("");
  
  const [notes, setNotes] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [description, setDescription] = useState("");
  const [data, setData] = useState({});
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [balance, setBalance] = useState(0);
  const [items, setItems] = useState([{ itemDetails: "", quantity: 1, rate: balance || 0 }]);
  const currency = "KES";  
  
  useEffect(() => {
    const fetchInvoiceNumber = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/invoices/latest`); // API endpoint to fetch the latest invoice
        const latestInvoice = response.data?.invoiceNumber || "INV000000";
        
        // Generate the next invoice number
        const nextInvoiceNumber = generateNextInvoiceNumber(latestInvoice);
        setInvoiceNumber(nextInvoiceNumber);
      } catch (error) {
        console.error("Error fetching latest invoice number:", error);
      }
    };

    fetchInvoiceNumber();
  }, []);

  // Function to generate the next invoice number
  const generateNextInvoiceNumber = (currentNumber) => {
    const prefix = currentNumber.match(/[^\d]+/g)?.[0] || "INV"; // Extract the prefix
    const number = parseInt(currentNumber.replace(prefix, ""), 10) || 0; // Extract and parse the numeric part
    const nextNumber = (number + 1).toString().padStart(6, "0"); // Increment and pad with zeros
    return `${prefix}${nextNumber}`;
  };
 
  useEffect(() => {
    const fetchStakeholders = async () => {
      try {
        const response = await axios.get(`${apiUrl}/stakeholders`);
        const datas = response.data;
        setStakes(datas);
        const dataOptions = datas.filter((d) => d.type === data?.recipientType);
        const options = dataOptions.map((stakeholder) => ({
          value: stakeholder.id,
          label: stakeholder.name,
        }));
        setStakeholders(options);
        const res = await axios.get(`${apiUrl}/api/transactions`);
        const trans = res.data.transactions;
        const transOptions = trans.filter((da) => da.stakeholder === selectedRecipient?.label);
        const transactions = transOptions.map((stakeholder) => ({
          value: stakeholder.id,
          label: `${stakeholder.tableName} - ${stakeholder.transactionId}`,
        }));
        setStakeholdersTrans(transactions);
      } catch (error) {
        console.error("Error fetching stakeholders:", error);
      }
    };

    fetchStakeholders();

  }, [data.recipientType, transactionData]);

  useEffect(() => {
    if (transactionData && transactionData.length > 0) {
      const { stakeholder, tableName, transactionId, toPay, paidAmount } = transactionData[0];
      console.log(stakeholder, tableName, transactionId, toPay, paidAmount);
  
      // Set recipient and description
      setSelectedRecipient({ label: stakeholder, value: stakeholder });
      setDescription(`${tableName} - Transaction ID: ${transactionId}`);
  
      // Calculate balance
      setBalance(toPay - paidAmount);
  
      // Find recipient type
      const stakeType = stakes.find((d) => d.name === stakeholder);
      
      console.log("pull", stakeType, stakeholder, transactionData, stakes);

      if (stakeType) {
        setRecipientType(stakeType?.type);
      }
    }
  }, [transactionData]);

  useEffect(() => {
    calculateDueDate();
  }, [terms, invoiceDate, customDuration]);

  const calculateDueDate = () => {
    const invoiceDateObj = new Date(invoiceDate);

    if (terms === "Due on Receipt") {
      setDueDate(format(invoiceDateObj, "yyyy-MM-dd"));
    } else if (typeof terms === "number") {
      setDueDate(format(addDays(invoiceDateObj, terms), "yyyy-MM-dd"));
    } else if (terms === "Custom" && customDuration) {
      setDueDate(format(addDays(invoiceDateObj, customDuration), "yyyy-MM-dd"));
    } else if (terms === "endOfMonth") {
      setDueDate(format(endOfMonth(invoiceDateObj), "yyyy-MM-dd"));
    } else if (terms === "endOfNextMonth") {
      const nextMonth = addDays(invoiceDateObj, 30); // Approximation for next month
      setDueDate(format(endOfMonth(nextMonth), "yyyy-MM-dd"));
    } else {
      setDueDate(""); // Default empty for unsupported cases
    }
  };
  
  useEffect(() => {
    if (terms === "Due on Receipt") {
      setDueDate(invoiceDate);
    } else if (terms === "Custom") {
      const date = new Date(invoiceDate);
      date.setDate(date.getDate() + customDuration);
      setDueDate(date.toISOString().slice(0, 10));
    }
  }, [terms, customDuration, invoiceDate]);
  
  const calculateSubtotal = () => {
    return items
      .reduce(
        (sum, item) =>
          sum + item.quantity * (item.rate || balance || 0),
        0
      )
      .toFixed(2);
  };
  
  const handleAddRow = () => {
    setItems([...items, { itemDetails: "", quantity: 1, rate: 0 }]);
  };
  
  const handleRemoveRow = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const invoiceData = {
      recipientType,
      selectedRecipient: selectedRecipient?.value || "",
      terms: terms === "Due on Receipt" ? terms : `${customDuration} Days`,
      invoiceNumber,
      invoiceDate,
      dueDate,
      items,
      notes,
      total: calculateSubtotal(),
    };

    try {
      await axios.post(`${apiUrl}/api/invoices`, invoiceData);
      alert("Invoice successfully created!");
      onHide();
    } catch (error) {
      console.error("Error creating invoice:", error);
      alert("Failed to create invoice.");
    }
  };

  const typeOptions = [
      { value: 'customer', label: 'Customer' },
      { value: 'supplier', label: 'Supplier' },
      { value: 'veterinary', label: 'Veterinary' },
  ];

  const termOptions = [
    { value: 15, label: "Net 15" },
    { value: 30, label: "Net 30" },
    { value: 45, label: "Net 45" },
    { value: 60, label: "Net 60" },
    { value: "Due on Receipt", label: "Due on Receipt" },
    { value: "endOfMonth", label: "Due end of the month" },
    { value: "endOfNextMonth", label: "Due end of next month" },
    { value: "Custom", label: "Custom" },
  ];

  const handleInput = (fieldName, value) => {
      setData((prevData) => ({
          ...prevData,
          [fieldName]: value,
      }));
      // console.log(data)
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>New Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Recipient Type</Form.Label>
                    <Select
                        options={typeOptions}
                        // value={recipientType}
                        value={typeOptions.find((option) => option.value === recipientType)}
                        onChange={(selectedOption) => setRecipientType(handleInput('recipientType', selectedOption.value))}
                        isSearchable
                        name="recipientType"
                        placeholder="--Select type--"
                        required
                        isDisabled={transactionData && transactionData.length > 0}
                    />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Select Recipient</Form.Label>                  
                  <Select
                    options={stakeholders}
                    value={selectedRecipient}
                    onChange={setSelectedRecipient}
                    placeholder="Select recipient"
                    isDisabled={transactionData && transactionData.length > 0}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
            <Col>
                <Form.Group>
                  <Form.Label>Order Number</Form.Label>                 
                  <Form.Control
                      type="text"
                      placeholder="Enter order number"
                      onChange={(e) => handleInput('orderNumber', (e.target.value))}
                      name="orderNumber"
                    />
                </Form.Group>
              </Col>
            <Col>
                <Form.Group>
                  <Form.Label>Invoice#</Form.Label>
                  <Form.Control type="text" value={invoiceNumber} readOnly />
                </Form.Group>
                </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`let your ${data?.recipientType || recipientType} know what this invoice is for`}
                      onChange={(e) => handleInput('description', (e.target.value))}
                      name="description"
                    />
                    </Form.Group>
                </Col>
            </Row>

    <Row className="mb-3">
      {/* Invoice Date */}
      <Col>
        <Form.Group>
          <Form.Label>Invoice Date</Form.Label>
          <Form.Control
            type="date"
            value={invoiceDate}
            onChange={(e) => setInvoiceDate(e.target.value)}
          />
        </Form.Group>
      </Col>

      {/* Terms */}
      <Col>
        <Form.Group>
          <Form.Label>Terms</Form.Label>
          <Select
            options={termOptions}
            value={termOptions.find((option) => option.value === terms)}
            onChange={(selectedOption) =>
              setTerms(selectedOption?.value ?? "Due on Receipt")
            }
            isSearchable
            name="terms"
            placeholder="--Select term--"
            required
          />
          {terms === "Custom" && (
            <Form.Control
              type="number"
              min="1"
              placeholder="Enter duration in days"
              value={customDuration ?? ""}
              onChange={(e) => setCustomDuration(Number(e.target.value))}
            />
          )}
        </Form.Group>
      </Col>

      {/* Due Date */}
      <Col>
        <Form.Group>
          <Form.Label>Due Date</Form.Label>
          <Form.Control type="date" value={dueDate} readOnly />
        </Form.Group>
      </Col>
    </Row>

            <Table bordered responsive>
              <thead>
                <tr>
                  <th>Item Details</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.itemDetails || description}
                        onChange={(e) => {
                          const newItems = [...items];
                          newItems[index].itemDetails = e.target.value;
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        min="1"
                        value={item.quantity}
                        onChange={(e) => {
                          const newItems = [...items];
                          newItems[index].quantity = Number(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        min="0"
                        value={item.rate || balance}
                        onChange={(e) => {
                          const newItems = [...items];
                          newItems[index].rate = Number(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>{(item.quantity * (item.rate || balance)).toFixed(2)}</td>
                    <td>
                      <Button variant="danger" size="sm" onClick={() => handleRemoveRow(index)}>
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button variant="secondary" onClick={handleAddRow}>
                + Add Item
            </Button>
            <Row>
                <Col>
                
                <Form.Group className="mt-3">
              <Form.Label><span className="text-capitalize">{data?.recipientType || recipientType}</span> Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Group>
</Col>
            <Col>
            <div className="text-end mt-3">
                <Table responsive hover striped>
                    <tbody>
                        <tr>
                            <td>Subtotal:</td>
                            <td>
                            {calculateSubtotal()}
                            </td>
                        </tr>
                        <tr>
                            <td>Total:</td>
                            <td>{currency} {calculateSubtotal()}</td>
                        </tr>
                        </tbody>
               </Table>
          
            </div> 
            </Col>

            
            </Row>
               
            {/* <Button variant="primary" type="submit">
                Save Invoice
              </Button> */}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvoiceFormModal;

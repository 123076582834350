import React, { useState, useEffect, useRef } from 'react';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import { Col, Row, Alert } from 'react-bootstrap';
import axios from 'axios';

const Receipt = ({ transactionId }) => {
    const [receiptData, setReceiptData] = useState(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const printContentRef = useRef(null);

    const handlePrint = () => {
        const printContent = printContentRef.current.innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
    };

    useEffect(() => {
        console.log('Transaction ID:', transactionId);
        console.log('Received Transaction ID in Receipt component:', transactionId);

        const fetchReceiptData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/all-summary-receipts/${transactionId}`);
                const { receiptData } = response.data;
                setReceiptData(receiptData[0]);
                // console.log(receiptData);
            } catch (error) {
                console.error('Error retrieving receipt data:', error);
            }
        };

        if (transactionId) {
            fetchReceiptData();
        }
    }, [transactionId]);

    if (!receiptData) {
        return <div>Loading receipt...</div>;
    }
    const avatarImage = '/assets/farm-logo.jpg';

    return (
        <div className="receipt" id="receipt-section" ref={printContentRef}>
            <Col sm={12} md={12} className="border-bottom">
                <div className="row d-flex align-items-center m-b-10">
                    <div className="col-4">
                        <img src={avatarImage} alt="Comapny Logo" style={{ width: '100px', height: 'auto', align: 'center' }} />
                    </div>
                    <div className="col-4"></div>
                    <div className="col-4 titless">
                        {/* <div>SFARMS.CO.KE</div> */}
                        <div>P.O BOX 800-222</div>
                        <div>Naivasha</div>
                        <div>Phone : 254 729 588 892</div>
                        <div>Email : info@sfarms.co.ke</div>
                    </div>
                </div>
            </Col>
            <h4 className="receipt-heading">
                <Alert variant="success">SFARMS SALES RECEIPT</Alert>
            </h4>
            <h4 className="receipt-heading">RECEIPT: #{receiptData.transactionId}</h4>
            <p className="receipt-heading">TIMESTAMP: {new Date(receiptData.createdAt).toLocaleString()}</p>
            {receiptData.cus_name && (
                <div className="receipt-item">
                    <span className="receipt-label">Customer:</span>
                    <span className="receipt-value">{receiptData.cus_name}</span>
                </div>
            )}
            {receiptData.birds && (
                <div className="receipt-item">
                    <span className="receipt-label">No. of Birds:</span>
                    <span className="receipt-value">{receiptData.birds}</span>
                </div>
            )}
            {receiptData.tagNumber && (
                <div className="receipt-item">
                    <span className="receipt-label">Tag No.:</span>
                    <span className="receipt-value">{receiptData.tagNumber}</span>
                </div>
            )}
            {receiptData.trays && (
                <div className="receipt-item">
                    <span className="receipt-label">No. of Trays:</span>
                    <span className="receipt-value">{receiptData.trays}</span>
                </div>
            )}
            {receiptData.totalKgs && (
                <div className="receipt-item">
                    <span className="receipt-label">Total Kgs:</span>
                    <span className="receipt-value">{receiptData.totalKgs}</span>
                </div>
            )}
            {receiptData.eggs && (
                <div className="receipt-item">
                    <span className="receipt-label">No. of Eggs:</span>
                    <span className="receipt-value">{receiptData.eggs}</span>
                </div>
            )}
            {receiptData.goats && (
                <div className="receipt-item">
                    <span className="receipt-label">No. of Goats:</span>
                    <span className="receipt-value">{receiptData.goats}</span>
                </div>
            )}
            {receiptData.weanersGroup && (
                <div className="receipt-item">
                    <span className="receipt-label">Weaners Group:</span>
                    <span className="receipt-value">{receiptData.weanersGroup}</span>
                </div>
            )}
            {receiptData.pigs && (
                <div className="receipt-item">
                    <span className="receipt-label">No. of Pigs:</span>
                    <span className="receipt-value">{receiptData.pigs}</span>
                </div>
            )}

            {receiptData.price && (
                <div className="receipt-item">
                    <span className="receipt-label">Price Per Item:</span>
                    <span className="receipt-value">Ksh. {receiptData.price.toLocaleString()}</span>
                </div>
            )}
            {receiptData.pay_method && (
                <div className="receipt-item">
                    <span className="receipt-label">Payment Mode:</span>
                    <span className="receipt-value">{receiptData.pay_method}</span>
                </div>
            )}
            {receiptData.delivery && (
                <div className="receipt-item">
                    <span className="receipt-label">Delivery Mode:</span>
                    <span className="receipt-value">{receiptData.delivery}</span>
                </div>
            )}
            {receiptData.del_guy && (
                <div className="receipt-item">
                    <span className="receipt-label">Delivery Guy:</span>
                    <span className="receipt-value">{receiptData.del_guy}</span>
                </div>
            )}
            {receiptData.pay_reference && (
                <div className="receipt-item">
                    <span className="receipt-label">Payment Reference:</span>
                    <span className="receipt-value">{receiptData.pay_reference}</span>
                </div>
            )}
            {receiptData.transactionId && (
                <div className="receipt-item">
                    <span className="receipt-label">Transaction ID :</span>
                    <span className="receipt-value">{receiptData.transactionId}</span>
                </div>
            )}
            {receiptData.createdAt && (
                <div className="receipt-item">
                    <span className="receipt-label">DATE :</span>
                    <span className="receipt-value">{new Date(receiptData.recorded).toLocaleDateString()}</span>
                </div>
            )}
            <hr />
            <div className="receipt-footer">
                <span className="receipt-label">Total Cost: </span>
                <span className="receipt-value">Ksh. {receiptData.totalCost.toLocaleString()}</span>
            </div>
            <div className="receipt-footer">
                <span className="receipt-label">Served By: </span>
                <span className="receipt-value">{receiptData.servedBy}</span>
            </div>
            <div className="receipt-footer">
                <h4>Welcome Again</h4>
            </div>
        </div>
    );
};

export default Receipt;

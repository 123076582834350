import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DatePicker, Card, Button, Table } from 'antd';
import config from '../../config';
import { Modal, Row, Col, Tooltip } from 'react-bootstrap';
import LineChart from '../charts/nvd3-chart/chart/LineChart';
import { Bar, Line, Pie } from 'react-chartjs-2';
import PieDonutChart from '../charts/nvd3-chart/chart/PieDonutChart';
import { Link } from 'react-router-dom';
import { XLSX } from 'xlsx';
import BarDiscreteChart from '../charts/nvd3-chart/chart/BarDiscreteChart';

const AccountDashboard = ({ selectedAccountDetails, onCancel, dateRange }) => {
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [recentTransactions, setRecentTransactions] = useState([]);
    const [incomeExpenseTrend, setIncomeExpenseTrend] = useState([]);
    const [details, setDetails] = useState([]);
    const [monthlyUsage, setMonthlyUsage] = useState([]);
    const [percentageDifferences, setPercentageDifferences] = useState([]);

    const [aggregatedView, setAggregatedView] = useState(true);

    const start = dateRange && dateRange[0] ? dateRange[0].toISOString().split('T')[0] : '1970-01-01';
    const end = dateRange && dateRange[1] ? dateRange[1].toISOString().split('T')[0] : new Date().toISOString().split('T')[0];

    useEffect(() => {
        fetchMonthlyUsage(selectedAccountDetails?.description);
    }, [selectedAccountDetails]);

    const fetchMonthlyUsage = async (account) => {
        try {
            const response = await axios.get(`${apiUrl}/api/monthly-usage?bankAccount=${account}`);
            setMonthlyUsage(response?.data);
            calculatePercentageDifferences(response?.data);
            // console.log(percentageDifferences)
        } catch (error) {
            console.error('Error fetching monthly usage data:', error);
        }
    };

    const calculatePercentageDifferences = (data) => {
        const differences = data.map((item, index, arr) => {
            if (index === 0) return { ...item, percentageDifference: 0 };
            const prevUsage = arr[index - 1].usage_count;
            const percentageDifference = prevUsage === 0 ? Infinity : ((item.usage_count - prevUsage) / prevUsage) * 100;
            return { ...item, percentageDifference: percentageDifference.toFixed(2) };
        });
        setPercentageDifferences(differences);
    };

    useEffect(() => {
        if (selectedAccountDetails) {
            fetchRecentTransactions(selectedAccountDetails?.description);
            fetchIncomeExpenseTrend(selectedAccountDetails?.description);
            fetchDetails(selectedAccountDetails?.description);
        }
    }, [selectedAccountDetails, dateRange, apiUrl]);

    const fetchRecentTransactions = (bankAccount) => {
        const start = dateRange[0] ? dateRange[0].toISOString() : '1970-01-01';
        const end = dateRange[1] ? dateRange[1].toISOString() : new Date().toISOString();
        fetch(`${apiUrl}/api/recent-transactions?bankAccount=${bankAccount}&limit=5&start=${start}&end=${end}`)
            .then((response) => response?.json())
            .then((data) => setRecentTransactions(data || 0))
            .catch((error) => console.error('Error fetching recent transactions:', error));
        // console.log(recentTransactions)
    };

    const fetchIncomeExpenseTrend = (bankAccount) => {
        const start = dateRange[0] ? dateRange[0].toISOString() : '1970-01-01';
        const end = dateRange[1] ? dateRange[1].toISOString() : new Date().toISOString();

        fetch(`${apiUrl}/api/expense-income-trend?bankAccount=${bankAccount}&start=${start}&end=${end}`)
            .then((response) => response?.json())
            .then((data) => {
                setIncomeExpenseTrend(data);
                // console.log(data)
            })
            .catch((error) => console.error('Error fetching income expense trend:', error));
    };

    const fetchDetails = (bankAccount) => {
        const start = dateRange[0] ? dateRange[0].toISOString() : '1970-01-01';
        const end = dateRange[1] ? dateRange[1].toISOString() : new Date().toISOString();

        fetch(`${apiUrl}/api/details-count?bankAccount=${bankAccount}&start=${start}&end=${end}`)
            .then((response) => response?.json())
            .then((data) => {
                setDetails(data);
            })
            .catch((error) => console.error('Error fetching income expense trend:', error));
    };

    let totalIncome = 0;
    let totalExpense = 0;

    if (Array.isArray(incomeExpenseTrend)) {
        totalIncome = incomeExpenseTrend?.reduce((acc, item) => acc + item.total_income, 0);
        totalExpense = incomeExpenseTrend?.reduce((acc, item) => acc + item.total_expense, 0);
    } else if (incomeExpenseTrend) {
        totalExpense = incomeExpenseTrend?.total_expense;
        totalIncome = incomeExpenseTrend?.total_income;
    }

    const formatNumber = (number) => {
        return (
            number !== null &&
            number?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        );
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return '-';
        }
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString)?.toLocaleDateString(undefined, options);
    };

    const labels = incomeExpenseTrend.map((item) => new Date(item.createdAt).toLocaleDateString());
    const expenses = incomeExpenseTrend.map((item) => item.total_expense || 0);
    const incomes = incomeExpenseTrend.map((item) => item.total_income || 0);

    const dataForExpensesAndIncomes = {
        labels: labels,
        datasets: [
            {
                label: 'Total Expenses',
                data: expenses,
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            },
            {
                label: 'Total Incomes',
                data: incomes,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    const aggregatedData = incomeExpenseTrend.reduce((acc, item) => {
        const date = new Date(item.createdAt);
        const month = date.toLocaleString('default', { year: 'numeric', month: 'short' });

        if (!acc[month]) {
            acc[month] = { total_expense: 0, total_income: 0 };
        }

        acc[month].total_expense += item.total_expense || 0;
        acc[month].total_income += item.total_income || 0;

        return acc;
    }, {});

    const aggregatedLabels = Object.keys(aggregatedData);
    const aggregatedExpenses = Object.values(aggregatedData).map((item) => item.total_expense);
    const aggregatedIncomes = Object.values(aggregatedData).map((item) => item.total_income);

    const expensesAndIncomesAggregated = {
        labels: aggregatedLabels,
        datasets: [
            {
                label: 'Total Expenses',
                data: aggregatedExpenses,
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            },
            {
                label: 'Total Incomes',
                data: aggregatedIncomes,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    const dataForChart = aggregatedView ? expensesAndIncomesAggregated : dataForExpensesAndIncomes;

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedAccountDetails?.description}-Account Summary-{formatDate(start)}-{formatDate(end)}{' '}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} sm={12} xl={6}>
                            <Card title="Account Information" className="mb-3">
                                <p className="m-1 border-bottom">
                                    Account ID: <strong style={{ color: '#ff8a65' }}>#{selectedAccountDetails?.id}</strong>{' '}
                                </p>
                                <p className="m-1 border-bottom">
                                    Current Balance:{' '}
                                    <strong style={{ color: '#ff8a65' }}>KES {formatNumber(selectedAccountDetails?.accountBalance)}</strong>
                                </p>
                                <p className="m-1 border-bottom">
                                    Account Number: <strong style={{ color: '#ff8a65' }}>{selectedAccountDetails?.accountNumber}</strong>
                                </p>
                                <p className="m-1 border-bottom">
                                    Created:{' '}
                                    <strong style={{ color: '#ff8a65' }}>
                                        {new Date(selectedAccountDetails?.timeStamp).toLocaleString()}
                                    </strong>
                                </p>
                            </Card>
                            <Card title="Account Information" className="mb-3">
                                <Row>
                                    <Col XLSX={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <p className="m-1 border-bottom">
                                            Total Income: <strong style={{ color: '#04a9f5' }}>KES {formatNumber(totalIncome)}</strong>
                                        </p>
                                        <p className="m-1 border-bottom">
                                            Total Expense: <strong style={{ color: '#A389D4' }}>KES {formatNumber(totalExpense)}</strong>
                                        </p>
                                    </Col>
                                    <Col XLSX={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <p className="m-1">
                                            <span className="border-bottom">Monthly Percentage Change:{' '}</span>
                                            {percentageDifferences &&
                                                percentageDifferences.map((item, index) => (
                                                    <div key={index} className="monthly-change-item">
                                                        <span className="m-1 border-bottom">
                                                            <strong>
                                                                {item?.month} - {item?.usage_count}
                                                            </strong>
                                                        </span>
                                                        <span
                                                            className={`m-1 border-bottom ${
                                                                item?.percentageDifference >= 0 ? 'text-c-blue' : 'text-c-red'
                                                            }`}
                                                        >
                                                            <strong>{item?.percentageDifference}%</strong>
                                                        </span>
                                                    </div>
                                                ))}
                                        </p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col md={12} sm={12} xl={6}>
                            <Card title="Income - Expense" className="mb-3">
                                {/* <LineChart width={600} height={300} data={incomeExpenseTrend}>
                                    <Line type="monotone" dataKey="balance" stroke="#8884d8" />
                                    <Tooltip />
                                </LineChart> */}
                                <Button onClick={() => setAggregatedView(!aggregatedView)}>
                                    Switch to {aggregatedView ? 'Initial' : 'Aggregated'} View
                                </Button>
                                <Bar data={dataForChart} />

                                {/* <Bar data={dataForExpensesAndIncomes}/> */}
                            </Card>
                        </Col>
                        <Col md={12} sm={12} lg={12} xl={6}>
                            <Card
                                title="5 Recent transactions"
                                className="mb-3"
                                extra={
                                    <Link to="/accounts/statement">
                                        <Button>
                                            <span className="feather icon-link"></span>
                                        </Button>
                                    </Link>
                                }
                            >
                                <Table
                                    dataSource={recentTransactions}
                                    columns={[
                                        { title: 'Date', dataIndex: 'createdAt', render: (text) => formatDate(text) || '-' },
                                        { title: 'Description', dataIndex: 'details' },
                                        { title: 'Debit', dataIndex: 'debit', render: (text) => formatNumber(text) || '-' },
                                        { title: 'Credit', dataIndex: 'credit', render: (text) => formatNumber(text) || '-' },
                                        { title: 'Balance', dataIndex: 'balance', render: (text) => formatNumber(text) || '-' }
                                    ]}
                                    loading={recentTransactions.length === 0}
                                    scroll={{ y: 200 }}
                                    onChange={(pagination, filters, sorter) => {
                                        console.log('Various parameters', pagination, filters, sorter);
                                    }}
                                    rowKey="transactionId"
                                />
                            </Card>
                        </Col>
                        <Col md={12} sm={12} lg={12} xl={6}>
                            <Card title="Account Activity" className="mb-3">
                                <PieDonutChart width={400} height={400} data={details}>
                                    <Pie
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={100}
                                        fill="#8884d8"
                                        label
                                        data={details}
                                    />
                                    <Tooltip />
                                </PieDonutChart>
                            </Card>
                        </Col>
                        <Col md={6} sm={6}></Col>
                    </Row>
                </Modal.Body>
            </Modal>
            {/* <Card as={Col} md={6} sm={6} title="Account Information" className="mb-3">
                        <div className="date-range-filter">
                            <DatePicker.RangePicker onChange={handleDateChange} />
                            <Button type="primary" onClick={() => handleDateChange(dateRange)}>
                                Apply
                            </Button>
                        </div>
                    </Card> */}
            {/* )} */}
        </React.Fragment>
    );
};

export default AccountDashboard;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Badge, Button, Form } from 'react-bootstrap';
import { Line, Bar } from 'react-chartjs-2';
import axios from 'axios';
import config from '../../../config';
import { subMonths, startOfMonth, endOfMonth, format } from 'date-fns';

const OverDash = () => {
    const [spendData, setSpendData] = useState([]);
    const [earnData, setEarnData] = useState([]);
    const [receivables, setReceivables] = useState({ current: 0, overdue: 0 });
    const [payables, setPayables] = useState({ current: 0, overdue: 0 });
    const [income, setIncome] = useState(0);
    const [expenses, setExpenses] = useState(0);
    const [incomeUnconfirmed, setUnconfirmedIncome] = useState(0);
    const [expensesUnconfirmed, setUnconfirmedExpenses] = useState(0);
    const [cashFlowData, setCashFlowData] = useState([]);
    const [incoming, setIncoming] = useState(0);
    const [outgoing, setOutgoing] = useState(0);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [activeTab, setActiveTab] = useState('3m');

    useEffect(() => {
        setTabDateRange('3m'); // Set the default range to 3 months
    }, []);

    const setTabDateRange = (range) => {
        const today = new Date();
        let start, end;

        switch (range) {
            case '3m':
                start = subMonths(today, 3);
                end = today;
                break;
            case '6m':
                start = subMonths(today, 6);
                end = today;
                break;
            case '1y':
                start = subMonths(today, 12); // 1 year ago
                end = today;
                break;
            case '2y':
                start = subMonths(today, 24); // 2 years ago
                end = today;
                break;
            default:
                start = startOfMonth(today); // Fallback: Start of the current month
                end = endOfMonth(today); // End of the current month
        }

        setStartDate(format(start, 'yyyy-MM-dd'));
        setEndDate(format(end, 'yyyy-MM-dd'));
        setActiveTab(range);
    };
    const fetchSpendData = async () => {
        const { data } = await axios.get(`${apiUrl}/spend?startDate=${startDate}&endDate=${endDate}`); // Replace with your spend API endpoint
        setSpendData(data);
        calculatePayables(data);
    };

    const fetchEarnData = async () => {
        const { data } = await axios.get(`${apiUrl}/earn?startDate=${startDate}&endDate=${endDate}`); // Replace with your earn API endpoint
        setEarnData(data);
        calculateReceivables(data);
    };

    useEffect(() => {
        fetchSpendData();
        fetchEarnData();
    }, [startDate, endDate]);

    const calculateReceivables = (earnData) => {
        const today = new Date();

        const current = earnData
            .filter((item) => !item.dueDate || new Date(item.dueDate) >= today) // Not overdue
            .reduce((acc, item) => acc + parseFloat((item.amount || 0) - (item.paidAmount || 0)), 0);

        const overdue = earnData
            .filter((item) => item.dueDate && new Date(item.dueDate) < today) // Overdue
            .reduce((acc, item) => acc + parseFloat((item.amount || 0) - (item.paidAmount || 0)), 0);

        const confirmedIncome = earnData
            .filter((item) => item.confirmDate)
            .reduce((acc, item) => acc + parseFloat(item.paidAmount || 0), 0);

        setReceivables({ current, overdue });
        setIncome(confirmedIncome);
        setUnconfirmedIncome(current + overdue);
        setIncoming(confirmedIncome);
        // setIncoming(current + overdue);
    };

    const calculatePayables = (spendData) => {
        const today = new Date();

        const current = spendData
            .filter((item) => !item.dueDate || new Date(item.dueDate) >= today) // Not overdue
            .reduce((acc, item) => acc + parseFloat((item.amount || 0) - (item.paidAmount || 0)), 0);

        const overdue = spendData
            .filter((item) => item.dueDate && new Date(item.dueDate) < today) // Overdue
            .reduce((acc, item) => acc + parseFloat((item.amount || 0) - (item.paidAmount || 0)), 0);

        // Confirmed Expenses: Use `paidAmount` for confirmed transactions
        const confirmedExpenses = spendData
            .filter((item) => item.confirmDate)
            .reduce((acc, item) => acc + parseFloat(item.paidAmount || 0), 0);

        // console.log(confirmedExpenses);

        setPayables({ current, overdue });
        setExpenses(confirmedExpenses);
        setUnconfirmedExpenses(current + overdue);
        setOutgoing(confirmedExpenses);
        // setOutgoing(current + overdue);
    };

    const revenue = income - expenses;
    const revenueUnconfirmed = incomeUnconfirmed - expensesUnconfirmed;

    useEffect(() => {
        const months = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
        const cashFlow = months.map((month) => {
            const incomeForMonth = earnData
                .filter((item) => new Date(item.createdAt).toLocaleString('default', { month: 'short' }) === month)
                .reduce((acc, item) => acc + parseFloat(item.paidAmount || 0), 0);

            const expenseForMonth = spendData
                .filter((item) => new Date(item.createdAt).toLocaleString('default', { month: 'short' }) === month)
                .reduce((acc, item) => acc + parseFloat(item.paidAmount || 0), 0);

            return incomeForMonth - expenseForMonth;
        });

        setCashFlowData(cashFlow);
    }, [earnData, spendData]);

    // Chart data for Cash Flow
    const chartData = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            {
                label: 'Cash Flow',
                data: cashFlowData,
                fill: true,
                borderColor: '#007bff',
                backgroundColor: 'rgba(0, 123, 255, 0.2)'
            }
        ]
    };

    // Bar graph data for Income and Expenses
    const incomeExpenseChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Income',
                data: earnData.map((item) => parseFloat(item?.paidAmount || 0)),
                backgroundColor: 'rgba(40, 167, 69, 0.6)', // Green
                borderColor: 'rgba(40, 167, 69, 1)',
                borderWidth: 1
            },
            {
                label: 'Expenses',
                data: spendData.map((item) => parseFloat(item?.paidAmount || 0)),
                backgroundColor: 'rgba(220, 53, 69, 0.6)', // Red
                borderColor: 'rgba(220, 53, 69, 1)',
                borderWidth: 1
            }
        ]
    };

    return (
        <Container fluid>
            <Card.Text className="align-items-center">
                <div>
                    <span className="pr-3">Filters:</span>
                    {['3m', '6m', '1y', '2y'].map((range) => (
                        <Button
                            key={range}
                            onClick={() => setTabDateRange(range)}
                            style={{
                                marginRight: '5px',
                                padding: '5px',
                                backgroundColor: activeTab === range ? '#007bff' : '#f8f9fa',
                                color: activeTab === range ? 'white' : 'black',
                                border: activeTab === range ? 'none' : '1px solid #ced4da',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}
                        >
                            {range === '3m'
                                ? 'Last 3 Months'
                                : range === '6m'
                                ? 'Last 6 Months'
                                : range === '1y'
                                ? 'Last 1 Year'
                                : 'Last 2 Years'}
                        </Button>
                    ))}
                    {/* </div>
                            <div> */}
                    <span className="px-3">Date Range:</span>
                    <Form.Label>
                        From: <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </Form.Label>
                    <Form.Label style={{ marginLeft: '20px' }}>
                        To: <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </Form.Label>

                    <span className="px-3">Exports:</span>
                </div>
                <hr />
            </Card.Text>

            <Row className="mt-4">
                {/* Receivables */}
                <Col md={4}>
                    <Card className="">
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-2">
                                    {' '}
                                    <img src="/assets/income.png" alt="Earnings" style={{ width: '50px' }} />
                                </div>

                                <div className="col-10 text-uppercase">
                                    <div>
                                        <h6 className="text-uppercase">Total Receivables</h6>
                                    </div>
                                    {/* <div className=""> */}
                                        <div>
                                            <strong>Current:</strong> <span className='font-weight-bold'>KES{' '}
                                            {receivables?.current?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2
                                            })}
                                            </span>
                                        </div>
                                        <div>
                                            <strong>Overdue:</strong> <span className='font-weight-bold'>KES{' '}
                                            {receivables?.overdue?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2
                                            })}</span>
                                        </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Payables */}
                <Col md={4}>
                    <Card className="">
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-2">
                                    {' '}
                                    <img src="/assets/expenses.png" alt="Earnings" style={{ width: '50px' }} />
                                </div>

                                <div className="col-10 text-uppercase">
                                    <div>
                                        <h6 className="text-uppercase">Total Payables</h6>
                                    </div>
                                    {/* <div className=""> */}
                                        <div>
                                            <strong>Current:</strong> <span className='font-weight-bold'>KES{' '}
                                            {payables?.current?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2
                                            })}</span>
                                        </div>
                                        <div>
                                            <strong>Overdue:</strong> <span className='font-weight-bold'>KES{' '}
                                            {payables?.overdue?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2
                                            })}</span>
                                        </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Payables */}
                <Col md={4}>
                    <Card className="">
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-2">
                                    {' '}
                                    <img src="/assets/cash.png" alt="Earnings" style={{ width: '50px' }} />
                                </div>

                                <div className="col-10 text-uppercase">
                                    <div>
                                        <h6 className="text-uppercase">Total Revenues</h6>
                                    </div>
                                    {/* <div className=""> */}
                                        <div>
                                            <strong>Confirmed:</strong> <span className='font-weight-bold'>KES{' '}
                                            {revenue?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2
                                            })}</span>
                                        </div>
                                        <div>
                                            <strong>Tentative:</strong> <span className='font-weight-bold'>KES{' '}
                                            {revenueUnconfirmed?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2
                                            })}</span>
                                        </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Cash Flow */}
            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Body>
                            <Card.Title>Cash Flow</Card.Title>
                            <Row>
                                <Col>
                                    <strong>Incoming:</strong> <span className='font-weight-bold'>KES{' '}
                                    {incoming?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2
                                    })}</span>
                                </Col>
                                <Col>
                                    <strong>Outgoing:</strong> <span className='font-weight-bold'>KES{' '}
                                    {outgoing?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2
                                    })}</span>
                                </Col>
                            </Row>
                                <hr/>
                            <Line data={chartData} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                {/* Income and Expense */}
                <Col md={6}>
                    <Card className="mb-4">
                        <Card.Body>
                            <Card.Title>Income and Expense</Card.Title>
                            <Card.Text>
                                <strong>Total Income:</strong> <span className='font-weight-bold'>KES{' '}
                                {income?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2
                                })}</span>
                                <br />
                                <strong>Total Expenses:</strong> <span className='font-weight-bold'>KES{' '}
                                {expenses?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2
                                })}</span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Top Expenses */}
                <Col md={6}>
                    <Card className="mb-4">
                        <Card.Body>
                            <Card.Title>Top Expenses</Card.Title>
                            <Card.Text>No Expense recorded for this fiscal year</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Body>
                            <Card.Title>Income and Expenses (Monthly)</Card.Title>
                            <Bar data={incomeExpenseChartData} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default OverDash;

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Form, Button, Alert, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import ModuleNotification from '../../../components/Widgets/Statistic/Notification';
import config from './../../../config';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
import { usePermissions } from '../../../permissions';

// import Card from '../../../components/Card/MainCard';

const BasicBreadcrumbPagination = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [idcard, setId] = useState(null);
    const [nhifcard, setNhif] = useState(null);
    const [nssfcard, setNssf] = useState(null);
    const [passport, setPassport] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const handleInput = (e) => {
        const { name, value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: value,
            is_admin: 'User',
            userName: account.user.firstname,
            userId: account.user.id
        }));
    };

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(`${apiUrl}/farm`);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [farm, apiUrl, farmOptions]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    const formData = new FormData();
    formData.append('firstname', data.firstname);
    formData.append('lastname', data.lastname);
    formData.append('email', data.email);
    formData.append('phone_number', data.phone_number);
    formData.append('is_admin', data.is_admin);
    formData.append('id_number', data.id_number);
    formData.append('mpesa_number', data.mpesa_number);
    // formData.append('password', data.password);
    formData.append('nssf', data.nssf);
    formData.append('nhif', data.nhif);
    formData.append('county', data.county);
    formData.append('farm', data.farm);
    formData.append('address', data.address);
    formData.append('leave_days', data.leave_days);
    formData.append('payment_rate', data.payment_rate);
    formData.append('emp_status', data.emp_status);
    formData.append('is_active', data.is_active);
    formData.append('nok_firstname', data.nok_firstname);
    formData.append('nok_lastname', data.nok_lastname);
    formData.append('nok_email', data.nok_email);
    formData.append('nok_phone_number', data.nok_phone_number);
    formData.append('nok_address', data.nok_address);
    formData.append('passport', passport);
    formData.append('id_card', idcard);
    formData.append('nhif_card', nhifcard || '');
    formData.append('nssf_card', nssfcard || '');
    formData.append('userName', account.user.firstname);
    formData.append('userId', account.user.id);

    const handleUser = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/users/new`, formData)
            .then((response) => {
                if (response.data.message === 'Email already registered') {
                    toast.error(`User with email ${formData.get('email')} already exists`);
                    setError(`User with email ${formData.get('email')} already exists`);
                    setShowConfirmationModal(false);
                } else if (response.data.message) {
                    setSuccess(`${formData.get('firstname')} ${formData.get('lastname')} added, password sent to ${formData.get('email')}`);
                    toast.success(response.data.message);
                    formRef.current.reset();
                    setData('');
                    setCurrentStep(1);
                    console.log(response);
                    setShowConfirmationModal(false);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal(false);
                }
                console.log(error);
                setShowConfirmationModal(false);
            });
    };

    const validateStep = () => {
        console.log('Current Step:', currentStep);
        console.log('Form Data:', data);

        // Check if all required fields in the current step are filled
        if (currentStep === 1) {
            const requiredFields = ['firstname', 'lastname', 'email', 'phone_number', 'id_number', 'mpesa_number', 'farm'];

            for (const field of requiredFields) {
                if (!data[field] || data[field].trim() === '') {
                    console.log(`Validation failed for ${field}`);
                    return false;
                }
            }

            console.log('Step 1 Validation: Passed');
            return true;
        }

        // Validation for Step 2
        if (currentStep === 2) {
            const requiredFields = ['nssf', 'nhif', 'county', 'address', 'leave_days', 'payment_rate', 'emp_status', 'is_active'];

            for (const field of requiredFields) {
                if (!data[field] || data[field].trim() === '') {
                    console.log(`Validation failed for ${field}`);
                    return false;
                }
            }

            console.log('Step 2 Validation: Passed');
            return true;
        }

        // Validation for Step 3
        if (currentStep === 3) {
            const requiredFields = ['nok_firstname', 'nok_lastname', 'nok_email', 'nok_phone_number', 'nok_address'];

            for (const field of requiredFields) {
                if (!data[field] || data[field].trim() === '') {
                    console.log(`Validation failed for ${field}`);
                    return false;
                }
            }

            console.log('Step 3 Validation: Passed');
            return true;
        }

        // Validation for Step 4
        if (currentStep === 4) {
            const requiredFields = ['passport', 'id_card'];

            for (const field of requiredFields) {
                if (!data[field] || data[field].trim() === '') {
                    console.log(`Validation failed for ${field}`);
                    return false;
                }
            }

            console.log('Step 4 Validation: Passed');
            return true;
        }

        return true; // Default to true if no specific validation for the step
    };

    const handleNext = () => {
        if (validateStep()) {
            setCurrentStep(currentStep + 1);
        } else {
            alert('Please fill in all required fields before proceeding.');
        }
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const handlePassport = (e) => {
        setPassport(e.target.files[0]);
    };
    const handleId = (e) => {
        setId(e.target.files[0]);
    };
    const handleNhif = (e) => {
        setNhif(e.target.files[0]);
    };
    const handleNssf = (e) => {
        setNssf(e.target.files[0]);
    };

    const handleRemove = (fileType) => {
        switch (fileType) {
            case 'passport':
                setPassport(null);
                break;
            case 'id_card':
                setId(null);
                break;
            case 'nhif_card':
                setNhif(null);
                break;
            case 'nssf_card':
                setNssf(null);
                break;
            default:
                break;
        }
    };

    const renderImagePreview = (file, fileType) => (
        <div>
            <img src={file ? URL.createObjectURL(file) : ''} alt={fileType} style={{ width: '30%', marginTop: '10px' }} />
            <Button variant="danger" size="sm" onClick={() => handleRemove(fileType)} style={{ marginTop: '5px' }}>
                Remove
            </Button>
        </div>
    );

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
             <Modal show={true} onHide={onCancel} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Form onSubmit={handleUser} ref={formRef} encType="multipart/form-data">
                {currentStep === 1 && (
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h5">Employee's Personal Details</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="text"
                                                placeholder="Enter First Name"
                                                name="firstname"
                                                value={data.firstname}
                                            />
                                            {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="text"
                                                placeholder="Enter Last Name"
                                                name="lastname"
                                                value={data.lastname}
                                            />
                                            {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="email"
                                                placeholder="examplea@gmail.com"
                                                name="email"
                                                value={data.email}
                                            />
                                            {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicPhone">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="tel"
                                                placeholder="254 726 635 232"
                                                name="phone_number"
                                                value={data.phone_number}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicID">
                                            <Form.Label>ID Number</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="number"
                                                placeholder="36544322"
                                                name="id_number"
                                                value={data.id_number}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Mpesa Number</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="number"
                                                placeholder="254 786 544 322"
                                                name="mpesa_number"
                                                value={data.mpesa_number}
                                            />
                                        </Form.Group>

                                        {/* <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="password"
                                                placeholder="Password"
                                                name="password"
                                                value={data.password}
                                            />
                                            <Form.Text className="text-muted">Enter strong and easy to remember password </Form.Text>
                                        </Form.Group> */}

                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Farm</Form.Label>
                                            <Select
                                                options={farmOptions}
                                                onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                                isSearchable
                                                name="farm"
                                                value={farmOptions.find((option) => option.value === data.farm)}
                                            />
                                            {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                        </Form.Group>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                {currentStep === 2 && (
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h5">Employement Details</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>NSSF</Form.Label>
                                            <Form.Control required onChange={handleInput} type="text" name="nssf" value={data.nssf} />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>NHIF</Form.Label>
                                            <Form.Control required onChange={handleInput} type="text" name="nhif" value={data.nhif} />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>County</Form.Label>
                                            <Form.Control required onChange={handleInput} type="text" name="county" value={data.county} />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control required onChange={handleInput} type="text" name="address" value={data.address} />
                                        </Form.Group>

                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Leave Days</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="text"
                                                name="leave_days"
                                                value={data.leave_days}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Payment Rate</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                onChange={handleInput}
                                                type="text"
                                                name="payment_rate"
                                                defaultValue={data.payment_rate}
                                            >
                                                <option />
                                                <option value="Per Day">Per Day</option>
                                                <option value="Per Month">Per Month</option>
                                                <option value="Per Week">Per Week</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Employement Status</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                onChange={handleInput}
                                                type="text"
                                                name="emp_status"
                                                defaultValue={data.emp_status}
                                            >
                                                <option />
                                                <option value="Permanent">Permanent</option>
                                                <option value="Contract">Contract</option>
                                                <option value="Temporary">Temporary</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                onChange={handleInput}
                                                type="text"
                                                name="is_active"
                                                defaultValue={data.is_active}
                                            >
                                                <option />
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </Form.Control>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md={6} sm={12} lg={6} >
                                            <Form.Label>User Role</Form.Label>
                                            <Form.Control as='select'  required onChange={handleInput} type="text" name="is_admin">
                                                <option />
                                                <option value="Admin">Admin</option>
                                                <option value="User">User</option>
                                            </Form.Control>
                                        </Form.Group> */}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                {currentStep === 3 && (
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h5">Next Of Kin Details</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Next of Kin First Name</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="text"
                                                name="nok_firstname"
                                                value={data.nok_firstname}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Next of Kin Last Name</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="text"
                                                name="nok_lastname"
                                                value={data.nok_lastname}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Next of Kin Email</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="email"
                                                name="nok_email"
                                                value={data.nok_email}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Next of Kin Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="text"
                                                name="nok_phone_number"
                                                value={data.nok_phone_number}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Next of Kin Address</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleInput}
                                                type="text"
                                                name="nok_address"
                                                value={data.nok_address}
                                            />
                                        </Form.Group>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                {currentStep === 4 && (
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h5">Upload Documents</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Form.Group as={Col} md={3} sm={12} lg={3}>
                                            <Form.Label>
                                                <code>*</code>PASSPORT PHOTO
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handlePassport}
                                                type="file"
                                                name="passport"
                                                accept=".jpg, .jpeg, .png"
                                                // value={passport}
                                            />
                                            {passport && <div>{renderImagePreview(passport, 'passport')}</div>}
                                        </Form.Group>
                                        <Form.Group as={Col} md={3} sm={12} lg={3}>
                                            <Form.Label>
                                                <code>*</code>ID PHOTO
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                onChange={handleId}
                                                type="file"
                                                name="id_card"
                                                accept=".jpg, .jpeg, .png"
                                                // value={idcard}
                                            />
                                            {idcard && <div>{renderImagePreview(idcard, 'id_card')}</div>}
                                        </Form.Group>
                                        <Form.Group as={Col} md={3} sm={12} lg={3}>
                                            <Form.Label>
                                                NHIF CARD PHOTO<code>Optional</code>
                                            </Form.Label>
                                            <Form.Control
                                                // required
                                                onChange={handleNhif}
                                                type="file"
                                                name="nhif_card"
                                                accept=".jpg, .jpeg, .png"
                                                // value={nhifcard}
                                            />
                                            {nhifcard && <div>{renderImagePreview(nhifcard, 'nhif_card')}</div>}
                                        </Form.Group>
                                        <Form.Group as={Col} md={3} sm={12} lg={3}>
                                            <Form.Label>
                                                NSSF CARD PHOTO<code>Optional</code>
                                            </Form.Label>
                                            <Form.Control
                                                // required
                                                onChange={handleNssf}
                                                type="file"
                                                name="nssf_card"
                                                accept=".jpg, .jpeg, .png"
                                                // value={nssfcard}
                                            />
                                            {nssfcard && <div>{renderImagePreview(nssfcard, 'nssf_card')}</div>}
                                        </Form.Group>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title as="h5">
                                    <Form.Text className="text-muted">
                                        **Please ensure all the deatails are all inserted and correctly filled!**
                                    </Form.Text>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col as={Col} md={12} sm={12} xl={12}>
                                        {currentStep > 1 && (
                                            <>
                                                {createPermission && (
                                                    <Button type="button" onClick={handlePrevious}>
                                                        Previous
                                                    </Button>
                                                )}
                                            </>
                                        )}

                                        {currentStep < 4 && (
                                            <>
                                                {createPermission && (
                                                    <Button type="button" onClick={handleNext}>
                                                        Next
                                                    </Button>
                                                )}
                                            </>
                                        )}

                                        {currentStep === 4 && <>{createPermission && <Button type="submit">Submit</Button>}</>}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Form>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
            </Modal.Body>
            </Modal>
            </React.Fragment>
    );
};

export default BasicBreadcrumbPagination;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';
import { LOGOUT } from './store/actions'; // Import your logout action
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socketIOClient from 'socket.io-client';
import config from './config';
import PermissionsProvider from './permissions';
import axios from 'axios';

const App = () => {
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const account = useSelector((state) => state.account);
    const dispatch = useDispatch();
    // const location = useLocation();
    // const userName = account?.user?.firstname;
    // const user_Id = account?.user?.id;
    // const ENDPOINT = ``;
    // const [onlineUsers, setOnlineUsers] = useState([]);
    // const socket = socketIOClient(ENDPOINT);
    // const [countdownActive, setCountdownActive] = useState(false);
    // const [currentOnlineUsers, setCurrentOnlineUsers] = useState([]);

    const getUserRole = () => {
        if (!account || !account.user) {
            return 'guest';
        } else if (account.user.is_admin === 'Admin') {
            return 'Admin';
        } else if (account.user.is_admin === 'User') {
            return 'User';
        } else if (account.user.is_admin === 'HR') {
            return 'HR';
        } else if (account.user.is_admin === 'Manager') {
            return 'Manager';
        } else {
            return 'View Only';
        }
    };

    const userRole = getUserRole();
    const inactivityTimeout = 10 * 60 * 1000; // 10 minutes in milliseconds (adjust as needed)
    let inactivityTimer;

    window.addEventListener('beforeunload', () => {
        sessionStorage.removeItem('token');
    });

    const resetInactivityTimer = () => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(() => {
            if (!areEventListenersActive()) {
                dispatch({ type: 'LOGOUT' });
            }
        }, inactivityTimeout);
    };

    const checkTokenExpiration = () => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
            if (decodedToken.exp < currentTime) {
                if (!areEventListenersActive()) {
                    localStorage.removeItem('token');
                    toast.error('Session expired. Please log in again.');
                    dispatch({ type: 'LOGOUT' });
                }
            }
        }
    };

    const areEventListenersActive = () => {
        return (
            window.onmousemove !== null &&
            window.onmousemove !== undefined &&
            window.onkeydown !== null &&
            window.onkeydown !== undefined
        );
    };

    useEffect(() => {
        resetInactivityTimer(); // Initialize inactivity timer
        checkTokenExpiration(); // Check token expiration on component mount

        // Attach event listeners to track user activity
        window.addEventListener('mousemove', resetInactivityTimer);
        window.addEventListener('keydown', resetInactivityTimer);

        // Cleanup: Remove event listeners and clear timer on component unmount
        return () => {
            clearTimeout(inactivityTimer);
            window.removeEventListener('mousemove', resetInactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
        };
    }, [dispatch]);

    // useEffect(() => {
    //     const socket = socketIOClient(ENDPOINT, {
    //         secure: true,
    //         reconnection: true,
    //         rejectUnauthorized: false
    //     });

    //     const userId = user_Id; // Example user ID
    //     socket.emit('user_online', userId);

    //     socket.on('disconnect', () => {
    //         console.log('Disconnected from server');
    //     });

    //     return () => {
    //         socket.disconnect();
    //     };
    // }, []);

    return (
        <React.Fragment>
            <ToastContainer />
            <Router basename={BASENAME}><PermissionsProvider>{renderRoutes(routes, userRole)}</PermissionsProvider></Router>
        </React.Fragment>
    );

};

export default App;

import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Table, Button, Pagination, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import config from '../../config';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import Select from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Card from '../../components/Card/MainCard';
import FarmExpenses from './FarmExpenses1';

const SamplePage = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [newAccount, setNewAccount] = useState({ accountName: '', accountBalance: '', description: '', type: '' });
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [totalDebits, setTotalDebits] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    const [expenses, setExpenses] = useState({ totalExpenses: 0 });
    const [earnings, setEarnings] = useState({ totalEarnings: 0 });
    const [data, setData] = useState([]);
    const [trialBalance, setTrialBalance] = useState([]);
    const [accountName, setAccountName] = useState('');
    const [type, setType] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);
    const tableRef = useRef(null);
    const [dailyEarnings, setDailyEarnings] = useState(0);
    const [weeklyEarnings, setWeeklyEarnings] = useState(0);
    const [monthlyEarnings, setMonthlyEarnings] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);

    useEffect(() => {
        // Fetch daily earnings
        axios
            .get(`${apiUrl}/earnings/stats?period=daily`)
            .then((response) => {
                setDailyEarnings(response.data.totalEarnings);
            })
            .catch((error) => {
                console.error('Error fetching daily earnings:', error);
            });

        // Fetch weekly earnings
        axios
            .get(`${apiUrl}/earnings/stats?period=weekly`)
            .then((response) => {
                setWeeklyEarnings(response.data.totalEarnings);
                console.log(response.data.totalEarnings);
            })
            .catch((error) => {
                console.error('Error fetching weekly earnings:', error);
            });

        // Fetch monthly earnings
        axios
            .get(`${apiUrl}/earnings/stats?period=monthly`)
            .then((response) => {
                setMonthlyEarnings(response.data.totalEarnings);
            })
            .catch((error) => {
                console.error('Error fetching monthly earnings:', error);
            });
    }, [apiUrl]);

    // useEffect(() => {
    //   // Fetch daily earnings
    //   axios.get(`${apiUrl}/expenses/stats?period=daily`)
    //     .then((response) => {
    //       setDailyEarnings(response.data.totalExpenses);
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching daily expenses:', error);
    //     });

    //   // Fetch weekly earnings
    //   axios.get(`${apiUrl}/expenses/stats?period=weekly`)
    //     .then((response) => {
    //       setWeeklyEarnings(response.data.totalExpenses);
    //       console.log(response.data.totalExpenses);
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching weekly expenses:', error);
    //     });

    //   // Fetch monthly earnings
    //   axios.get(`${apiUrl}/expenses/stats?period=monthly`)
    //     .then((response) => {
    //       setMonthlyEarnings(response.data.totalExpenses);
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching monthly expenses:', error);
    //     });
    // }, []);

    useEffect(() => {
        fetch(`${apiUrl}/expenses`)
            .then((response) => response.json())
            .then((expenses) => setExpenses(expenses))
            .catch((error) => console.error('Error:', error));
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/earnings`)
            .then((response) => response.json())
            .then((data) => setEarnings(data))
            .catch((error) => console.error('Error:', error));
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/accounts`)
            .then((response) => response.json())
            .then((data) => {
                setAccounts(data);
                calculateTotals(data);
            })
            .catch((error) => console.error('Error fetching accounts: ', error));
    }, [apiUrl, accounts]);

    const calculateTotals = (accounts) => {
        let debitTotal = 0;
        let creditTotal = 0;

        accounts.forEach((account) => {
            if (account.type === 'Debit') {
                debitTotal += Number(account.accountBalance);
            } else if (account.type === 'Credit') {
                creditTotal += Number(account.accountBalance);
            }
        });

        setTotalDebit(debitTotal);
        setTotalCredit(creditTotal);
    };

    const profitLoss = totalDebit - totalCredit;

    const profitLossF = earnings.totalEarnings - expenses.totalExpenses;

    const profitLossClass = profitLoss || profitLossF >= 0 ? 'profit' : 'loss';

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        // Get the UTC values for year, month, and day
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        // Calculate the age difference in milliseconds
        const ageDiffMillis = todayUTC - birthUTC;

        // Convert milliseconds to years, months, and days
        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
        } else {
            return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Today Earnings">
                                <div className="row d-flex align-items-center">
                                    <div className="col-12">
                                        <h6>Ksh {dailyEarnings.toLocaleString()}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Weekly Earnings">
                                <div className="row d-flex align-items-cente">
                                    <div className="col-12">
                                        <h6>Ksh {weeklyEarnings.toLocaleString()}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Monthly Earnings">
                                <div className="row d-flex align-items-cente">
                                    <div className="col-12">
                                        <h6>Ksh {monthlyEarnings.toLocaleString()}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Earnings">
                                <div className="row d-flex align-items-cente">
                                    <div className="col-12">
                                        <h6>Ksh {earnings.totalEarnings.toLocaleString()}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        {/* <Col sm={12} md={12} lg={12}>
                                      <Card>
                                    <div className="row d-flex align-items-center" style={{ color: profitLossF >= 0 ? 'green' : 'red'}}>
                                        <div className="col-6">
                                            <span className="d-block text-uppercase">{profitLossF >= 0 ? 'Profit: ' : 'Loss: '}</span>
                                        </div>

                                        <div className="col-6 text-right">
                                        Ksh {Math.abs(profitLossF).toLocaleString()}
                                        </div>
                                    </div>
                                    </Card>
                                </Col> */}
                    </Row>

                    <Card title="Tentative Earnings list" isOption>
                        <FarmExpenses />
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SamplePage;

import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, PDFViewer, Image} from '@react-pdf/renderer';

import MontserratRegular from './../../fonts/static/Montserrat-Regular.ttf';
import MontserratBold from './../../fonts/static/Montserrat-Bold.ttf';


Font.register({
    family: 'Montserrat',
    fonts: [
        {
            src: MontserratRegular,
            fontWeight: 'normal'
        },
        {
            src: MontserratBold,
            fontWeight: 'bold'
        }
    ]
});

const avatarImage = '/assets/farm-logo.jpg';

const Footer = ({ pageNumber, totalPages }) => (
    <View style={styles.footer} fixed>
        <Text style={styles.footerText}>
            {/* Page {pageNumber} of {totalPages} - This is a computer-generated document and does not require a signature. */}
            SIFLEON FARMS POULTRY AND PIGGERY
        </Text>
    </View>
);

export const PDFContent = ({ id, transactionData  }) => (
    <Document>
      <Page style={styles.page} size="A4" orientation="potrait">
        <View style={styles.section}>
        <View style={styles.row}> 
            <View style={styles.header} fixed>
              <View style={styles.rows}>
                <View style={styles.column}>
                  <Image source={avatarImage} style={styles.letterheadLogo} />
                </View>
              
                <View style={styles.column}>

                </View>
                <View style={{ ...styles.column, textAlign: 'right'}}>
                  <Text>SFARMS.CO.KE</Text>
                  <Text>P.O BOX 800-222</Text>
                  <Text>Naivasha</Text>
                  <Text>Phone : 254-787-689-760</Text>
                  <Text>Email : info@sfarms.co.ke</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{...styles.rowsc}}>
              <Text style={styles.cols}>{transactionData.cus_name ? 'Bill To' : "Bill From"}</Text>
              <Text style={styles.cols}>{transactionData.vetenary || transactionData.cus_name}</Text>
          </View>
          <View style={{...styles.row, fontWeight: 'bold', borderBottom: '0.5px solid grey', fontSize: 14, ...styles.tableRowEven}}>  
            {transactionData && (
              <View style={{ ...styles.column }}>
                <Text style={styles.cols}>RECEIPT TOTAL</Text>
              </View>
            )}

            {transactionData && (
              <View style={{ ...styles.column, textAlign: 'right' }}>
                {transactionData.totalCost && <Text style={styles.cols}>
                   KES {(transactionData?.totalCost).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </Text>}
                </View>
            )}
          </View>

          <View style={styles.row}>
              {transactionData && (
                <View style={styles.column}>
                     {transactionData.cus_name && <Text style={{...styles.cols, ...styles.tableRowEven}}>Customer:</Text>}
                     {(transactionData.birds || transactionData.pigs) && <Text style={{...styles.cols, ...styles.tableRowEven}}>Livestock:</Text>}{transactionData.weanersGroup && <Text style={{...styles.cols, ...styles.tableRowEven}}>Weaners Group:</Text>}
                     {transactionData.trays && <Text style={{...styles.cols, ...styles.tableRowEven}}>No. of Trays:</Text>}
                     {transactionData.eggs && <Text style={{...styles.cols, ...styles.tableRowEven}}>No. of Eggs:</Text>}
                     {transactionData.totalKgs && <Text style={{...styles.cols, ...styles.tableRowEven}}>Total Kgs:</Text>}
                     {transactionData.price && <Text style={{...styles.cols, ...styles.tableRowEven}}>Price Per Item:</Text>}
                     {transactionData.pay_method && <Text style={{...styles.cols, ...styles.tableRowEven}}>Payment Method:</Text>}
                     {transactionData.delivery && <Text style={{...styles.cols, ...styles.tableRowEven}}>Delivery Mode:</Text>}
                     {transactionData.vetenary && <Text style={{...styles.cols, ...styles.tableRowEven}}>Vetenary:</Text>}
                     {transactionData.animal && <Text style={{...styles.cols, ...styles.tableRowEven}}>Animal(s):</Text>}
                     {transactionData.pay_reference && <Text style={{...styles.cols, ...styles.tableRowEven}}>Reference:</Text>}
                     {transactionData.transactionId && <Text style={{...styles.cols, ...styles.tableRowEven}}>Transaction Id:</Text>}
                     {transactionData.recorded && <Text style={{...styles.cols, ...styles.tableRowEven}}>Recorded:</Text>}
                     {transactionData.createdAt && <Text style={{...styles.cols, ...styles.tableRowEven}}>Timestamp:</Text>}
                </View>
              )}
  
                {transactionData && (
                    <View style={{ ...styles.column, textAlign: 'right' }}>
                        {transactionData.cus_name && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.cus_name}</Text>}
                        {(transactionData.birds || transactionData.pigs) && (
          <Text style={{...styles.cols, ...styles.tableRowEven}}>
            {transactionData.birds ? `${transactionData.birds} Birds` : ''}
            {transactionData.birds && transactionData.pigs ? ' & ' : ''}
            {transactionData.pigs ? `${transactionData.pigs} Pigs` : ''}
          </Text>
        )}{transactionData.weanersGroup && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.weanersGroup}</Text>}
                        {transactionData.trays && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.trays}</Text>}
                        {transactionData.eggs && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.eggs}</Text>}
                        {transactionData.totalKgs && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.totalKgs}</Text>}
                        {transactionData.price && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.price}</Text>}
                        {transactionData.pay_method && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.pay_method}</Text>}
                        {transactionData.delivery && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.delivery}</Text>}
                        {transactionData.vetenary && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.vetenary}</Text>}
                        {transactionData.animal && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.animal}</Text>}
                        {transactionData.pay_reference && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.pay_reference}</Text>}
                        {transactionData.transactionId && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.transactionId}</Text>}
                        {transactionData.recorded && <Text style={{...styles.cols, ...styles.tableRowEven}}>{new Date(transactionData.recorded).toLocaleDateString()}</Text>}
                        {transactionData.createdAt && <Text style={{...styles.cols, ...styles.tableRowEven}}>{new Date(transactionData.createdAt).toLocaleString()}</Text>}
                    </View>
                )}
          </View>

          <View style={styles.separator} />
  
          <View style={styles.row}>  
            {transactionData && (
              <View style={{ ...styles.column }}>
               {transactionData.totalCost && <Text style={{...styles.cols, ...styles.tableRowEven}}>Amount:</Text>}
               <Text style={{...styles.cols, ...styles.tableRowEven}}>Tax:</Text>
               {transactionData.servedBy && <Text style={{...styles.cols, ...styles.tableRowEven}}>Served By:</Text>}
              </View>
            )}

            {transactionData && (
              <View style={{ ...styles.column, textAlign: 'right' }}>
                {transactionData.totalCost && <Text style={{...styles.cols, ...styles.tableRowEven}}>
                   KES {(transactionData?.totalCost).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </Text>}
                <Text style={{...styles.cols, ...styles.tableRowEven}}>
                  00.00
                </Text>
                {transactionData.servedBy && <Text style={{...styles.cols, ...styles.tableRowEven}}>{transactionData.servedBy}</Text>}
              </View>
            )}
          </View>
  
          <View style={{...styles.content, fontWeight: 'bold'}}>
            {transactionData && transactionData.remarks && <Text style={styles.notes}>Notes : {transactionData.remarks}</Text>}
          </View>
          
        </View><View style={{...styles.row, fontWeight: 'bold'}}>
            <Text style={styles.footer}>Thank you for your transaction!</Text>
          </View>
        <Footer />
      </Page>
    </Document>
);
  
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Montserrat',
        fontSize: 9,
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        width: '100%',
        marginTop: 25,
        height: '20px',
    },
    section: {
        marginTop: 5,
        marginLeft: 60,
        marginRight: 60,
        padding: 10,
        flexGrow: 1,
    },
    header: {
        width: '100%',
        alignContent: 'center',
    },
    letterheadLogo: {
        width: 100,
        height: 100,
    },
    rows: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems:'center',
    },
    transactionId: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 10,
        // color: '#ffffff',
        borderBottom: '0.5px solid grey',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    rowsc: {
        display: 'grid',
        flexDirection: 'column',
        marginBottom: 10,
    },
    column: {
        width: '50%',
    },
    cols: {
        padding: 1,
        textTransform: "uppercase"
    },
    tableRowEven: {
      backgroundColor: '#f2f2f2',
      padding:4,
      marginBottom: 3
    },
    tableRowOdd: {
        backgroundColor: '#ffffff' // White for odd rows
    },
    label: {
      width: "50%",
      fontWeight: "bold",
    },
    value: {
      width: "50%",
      textAlign: "right",
    },
    separator: {
      marginVertical: 10,
      borderBottomWidth: 1,
      borderBottomColor: "#000",
    },
    notes: {
        marginTop: 20,
        fontSize: 10,
        borderTop: '0.5px solid grey',
        borderBottom: '0.5px solid grey',
        padding: 1,
    },
    footer: {
        position: 'absolute',
        bottom: 50,
        left: 40,
        right: 40,
        textAlign: 'center',
        fontSize: 8,
        borderTop: '1px solid #000',
        paddingTop: 5,
        backgroundColor: '#f2f2f2',
        // paddingBottom: 10,
        // width: '50%',
    },
    footerText: {
        color: '#000'
    }
});

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import { useHistory, useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import axios from 'axios';
import Receipt from '../ui-elements/basic/Receipt2';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';
import {PDFDownloadLink} from '@react-pdf/renderer';
import { PDFContent } from './../../components/Receipts';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();
    const [transactionId, setTransactionId] = useState(null);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedAnimal, setSelectedAnimal] = useState(null);
    const [animal, setAnimal] = useState([]);
    const [vaccineOptions, setVaccineOptions] = useState([]);
    const [selectedVaccine, setSelectedVaccine] = useState(null);
    const [vetOptions, setVetOptions] = useState([]);
    const [selectedVet, setSelectedVet] = useState(null);
    const [vaccineCost, setVaccineCost] = useState(0);
    const [showReceipt, setShowReceipt] = useState(false);
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [accounts, setAccounts] = useState([]);
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [receiptData, setReceiptData] = useState(null);

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/accounts`;
    }, [apiUrl]);

    const memoizedApiUrl3 = useMemo(() => {
        return `${apiUrl}/vetenary`;
    }, [apiUrl]);

    const memoizedApiUrl4 = useMemo(() => {
        return `${apiUrl}/vaccines`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    useEffect(() => {
        fetchAccounts();
    }, [memoizedApiUrl2]);

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(memoizedApiUrl2);
            const accounts = response.data;

            // const filteredData = accounts.filter((tag) => tag.farm === farm);

            // const filteredAccounts = accounts.filter(account => account.accountName !== 'Income');

            setAccounts(accounts);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    const handleSelectChange = (name, selectedOption) => {
        setData({ ...data, [name]: selectedOption.value });
    };

    useEffect(() => {
        const fetchFeeds = async () => {
            try {
                const response = await axios.get(memoizedApiUrl3);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((feed) => ({
                    value: feed.name,
                    label: feed.name
                }));
                setVetOptions(options);
            } catch (error) {
                console.error('Error fetching vets:', error);
            }
        };

        fetchFeeds();
    }, [memoizedApiUrl3]);

    const handleInput = (fieldName, value) => {
        if (fieldName === 'animal') {
            setSelectedAnimal(value);
            const animal = value ? value.map((option) => option.value) : [];
            setData((prevData) => ({
                ...prevData,
                [fieldName]: value,
                animal,
                servedBy: account.user.firstname,
                userName: account.user.firstname,
                userId: account.user.id
            }));
        } else {
            setData((prevData) => ({
                ...prevData,
                [fieldName]: value,
                servedBy: account.user.firstname,
                userName: account.user.firstname,
                userId: account.user.id
            }));
        }
        console.log(data);
    };

    useEffect(() => {
        const fetchVaccines = async () => {
            try {
                const response = await axios.get(memoizedApiUrl4);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((vaccine) => ({
                    value: vaccine.vaccine,
                    label: vaccine.vaccine
                }));
                console.log(options);
                setVaccineOptions(options);
            } catch (error) {
                console.error('Error fetching vaccines:', error);
            }
        };

        fetchVaccines();
    }, [memoizedApiUrl4]);

    const handleEggs = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/emergency/vaccination`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(`${data.vetenary} successfully vaccinated animals`);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(`${data.vetenary} successfully vaccinated animals`);
                    const transactionId = response.data.transactionId;
                    setTransactionId(transactionId);
                    setShowReceipt(true);
                    setShowReceiptModal(true);
                    formRef.current.reset();
                    setSelectedAnimal(null);
                    setSelectedVet(null);
                    setVaccineCost(null);
                    setSelectedVaccine(null);
                    setShowConfirmationModal(false);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                } else {
                    toast.error(`Error vaccinating animals`);
                    setError(`Error vaccinating animals`);
                    setShowConfirmationModal(false);
                }
                console.log(error);
                setShowConfirmationModal(false);
            });
    };

    const animalOptions = [
        { value: 'Pigs', label: 'Pigs' },
        { value: 'Goats', label: 'Goats' },
        { value: 'Poultry', label: 'Poultry' }
    ];

    const handleCloseReceiptModal = () => {
        setShowReceiptModal(false);
        window.location.reload();
    };

    const handlePrint = () => {
        window.print();
    };

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    useEffect(() => {
        const fetchTransactionData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/emergency_vaccination_receipt/${transactionId}`);
                setReceiptData(response.data.receiptData);
                // console.log(response.data.receiptData);
            } catch (error) {
                console.error('Error retrieving receipt data:', error);
            }
        };

        fetchTransactionData();
    }, [transactionId]);

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Emergency Vaccination</Modal.Title>
                </Modal.Header>
                <Modal.Body>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Form onSubmit={handleEggs} ref={formRef}>
                <Row>
                    <Col>
                            <Row>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Farm</Form.Label>
                                    <Select
                                        options={farmOptions}
                                        onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                        isSearchable
                                        name="farm"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Category Visited</Form.Label>
                                    <Select
                                        value={selectedAnimal}
                                        onChange={(selectedOption) => setSelectedAnimal(handleInput('animal', selectedOption))}
                                        options={animalOptions}
                                        isSearchable
                                        isMulti
                                        name="animal"
                                        placeholder="-- Category to Vaccinate --"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Vetenary Officer</Form.Label>
                                    <Select
                                        value={selectedVet}
                                        onChange={(selectedOption) => setSelectedVet(handleInput('vetenary', selectedOption.value))}
                                        options={vetOptions}
                                        isSearchable
                                        name="vetenary"
                                        placeholder="-- Select Vetenary --"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Total Cost</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        onChange={(e) => handleInput('totalCost', e.target.value)}
                                        name="totalCost"
                                        required
                                        type="float"
                                    />
                                </Form.Group>
                                <Col md={6} sm={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>Expense Account:</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => handleSelectChange('bankAccount', selectedOption)}
                                            options={accounts.map((account) => ({
                                                value: account.description,
                                                label: (account.accountNumber, account.description)
                                            }))}
                                            placeholder="Select account"
                                            required
                                        />
                                    </Form.Group>
                                </Col>

                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={(e) => handleInput('recorded', e.target.value)}
                                        type="date"
                                        name="recorded"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                    <Form.Label>Remarks</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        onChange={(e) => handleInput('remarks', e.target.value)}
                                        name="remarks"
                                        required
                                        type="text"
                                    />
                                </Form.Group>
                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                        <Form.Text className="text-muted">
                                            **<code>Record precise and simple remarks </code>
                                        </Form.Text>
                                    </Form.Group>
                            </Row>
                            <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                    </Col>
                </Row>
            </Form>
            </Modal.Body>
            </Modal>
            {showReceipt && (
                <Modal show={showReceiptModal} onHide={handleCloseReceiptModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Receipt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Receipt transactionId={transactionId} />
                    </Modal.Body>
                    <Modal.Footer>
                    {receiptData && receiptData.transactionId  && (
                                <PDFDownloadLink
                                    className="btn btn-success mr-2"
                                    document={
                                        <PDFContent
                                            id={receiptData?.id}
                                            transactionData={receiptData}
                                        />
                                    }
                                    fileName={`${receiptData?.transactionId}_${receiptData?.createdAt}.pdf`}
                                >
                                    {({ blob, url, loading, error }) => (loading ? 'Loading...' : <span className='feather icon-printer'> Print</span>)}
                                    </PDFDownloadLink>
                                )}
                        <Button variant="secondary" onClick={handleCloseReceiptModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;

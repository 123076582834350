import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModuleNotification from '../../../components/Widgets/Statistic/Notification';
import config from '../../../config';
import axios from 'axios';
import { usePermissions } from '../../../permissions';

// import Card from '../../../components/Card/MainCard';

const BasicBreadcrumbPagination = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const handleInput = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
        console.log(data);
    };

    const handleUser = async (e) => {
        e.preventDefault();
        // if (createPermission) {
        //     return;
        // }
        try {
            const response = await axios.post(`${apiUrl}/permissions`, data);

            if (response.data) {
                setSuccess();
                setTimeout(() => {
                    setSuccess(null);
                }, 5000);
                toast.success(response.data.message);
                formRef.current.reset();
                setData({});
            }
            // if (response.data && response.data.success) {
            //     // Extract newly created permission ID from the response
            //     const newPermissionId = response.data.id;

            //     // Add the new permission to all roles
            //     await axios.post(`${apiUrl}/permissions/add-to-roles/${newPermissionId}`, {
            //         create_permission: true,
            //         read_permission: true,
            //         update_permission: true,
            //         delete_permission: true
            //     });

            //     // Show success message and reset form
            //     setSuccess();
            //     setTimeout(() => {
            //         setSuccess(null);
            //     }, 5000);
            //     toast.success(response.data.message);
            //     formRef.current.reset();
            //     setData({});
            // } else {
            //     throw new Error(response.data.message || 'Failed to create permission');
            // }
        } catch (error) {
            const errorMessage = error.response ? error.response.data.message : error.message;
            setError(errorMessage);
            setTimeout(() => {
                setError(null);
            }, 5000);
            console.error(errorMessage);
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Row></Row>
            <Row>
                <Col sm={12}>
                    <Link to="/users/roles">
                        <Button variant="primary" className="mb-3 mt-2">
                            <span className="feather icon-arrow-left"></span> Go Back
                        </Button>
                    </Link>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Add Models/Permissions</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleUser} ref={formRef}>
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control required onChange={handleInput} type="text" name="name" />
                                    </Form.Group>
                                    {!createPermission && (
                                        <Form.Group as={Col} md={12} sm={12} lg={12}>
                                            <Button type="submit">Submit</Button>
                                        </Form.Group>
                                    )}
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BasicBreadcrumbPagination;

import React from 'react';
import BalanceSheetTable from "./BalanceSheetTable"
const BalanceSheet = () => {
  return (
    <div>
        <BalanceSheetTable />
    </div>
  )
}

export default BalanceSheet;

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import config from '../../../config';
import axios from 'axios';
import { Button, Card, Form } from 'react-bootstrap';
import { subMonths, startOfMonth, endOfMonth, format } from 'date-fns';

const BalanceSheetTable = () => {
    const [data, setData] = useState([]);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;

     const [startDate, setStartDate] = useState('');
        const [endDate, setEndDate] = useState('');
        const [activeTab, setActiveTab] = useState('3m');
    
        useEffect(() => {
            setTabDateRange('3m'); // Set the default range to 3 months
        }, []);
    
        const setTabDateRange = (range) => {
            const today = new Date();
            let start, end;
    
            switch (range) {
                case '3m':
                    start = subMonths(today, 3);
                    end = today;
                    break;
                case '6m':
                    start = subMonths(today, 6);
                    end = today;
                    break;
                case '1y':
                    start = subMonths(today, 12); // 1 year ago
                    end = today;
                    break;
                case '2y':
                    start = subMonths(today, 24); // 2 years ago
                    end = today;
                    break;
                default:
                    start = startOfMonth(today); // Fallback: Start of the current month
                    end = endOfMonth(today); // End of the current month
            }
    
            setStartDate(format(start, 'yyyy-MM-dd'));
            setEndDate(format(end, 'yyyy-MM-dd'));
            setActiveTab(range);
        };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/balanceSheet?startDate=${startDate}&endDate=${endDate}`);
                const balance = response.data;
                const transformedData = await transformData(balance);
                setData(transformedData);
                // console.log(balance);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [startDate, endDate]);

    const fetchAccountsFromDB = async () => {
        try {
            const response = await axios.get(`${apiUrl}/accounts`);
            // console.log(response.data)
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    const accountCategories = {
        Assets: {
            subcategories: ['Other Asset', 'Other Current Asset', 'Cash', 'Bank', 'Fixed Asset', 'Stock', 'Payment Clearing' ]
        },
        Liability: {
            subcategories: ['Other Current Liability', 'Credit Card', 'Long Term Liability', 'Other Liability']
        },
        Equity: {
            subcategories: ['Equity']
        },
        Income: {
            subcategories: ['Income', 'Other Income']
        },
        Expense: {
            subcategories: ['Expense', 'Cost of Goods Sold', 'Other Expense']
        }
    };
    
    const transformData = async (balanceSheetData) => {
      const accountDataFromDB = await fetchAccountsFromDB();
      const transformed = [];
      const categoryTotals = {}; // Store totals for each category
      const subcategoryDetails = {};

      const categorizeAccount = (bankAccountDescription) => {
        // Loop through account categories
        for (const category in accountCategories) {
            const subcategories = accountCategories[category].subcategories;
    
            // console.log(subcategories);
            // console.log(bankAccountDescription);
    
            // Find account data by exact match with bankAccountDescription
            const accountData = accountDataFromDB.find(
                (account) => account.description.toLowerCase() === bankAccountDescription.toLowerCase()
            );
    
            // console.log(accountData);
    
            if (accountData) {
                const matchedSubcategory = subcategories.find(
                    (subcategory) => subcategory === accountData.category
                );
    
                // console.log(matchedSubcategory);
    
                if (matchedSubcategory) {
                    return {
                        type: accountData.type,
                        category: category,
                        gl_code: accountData.gl_code,
                        subcategory: matchedSubcategory,
                        account_id: accountData.id,
                    };
                }
            }
        }
    
        // console.warn(`Uncategorized account: ${bankAccountDescription}`);
        return {
            category: 'Uncategorized',
            gl_code: 'N/A',
            subcategory: 'Unknown',
            account_id: null,
        };
    };
    
      balanceSheetData.forEach((account, index) => {
          const { category, subcategory, gl_code } = categorizeAccount(account.bankAccount);
  
          // Add account details under the appropriate subcategory
          if (!subcategoryDetails[subcategory]) {
              subcategoryDetails[subcategory] = [];
          }
          subcategoryDetails[subcategory].push({
              id: `${category}-${subcategory}-${index}`,
              account: account.bankAccount,
              gl_code: gl_code,
              total: account.totalAssets + account.totalSpend + account.totalEarn,
              totalAssets: account.totalAssets,
              totalSpend: account.totalSpend,
              totalEarn: account.totalEarn,
              category: category,
              subcategory: subcategory,
              account_id: account.id,
              isHeader: false,
              isSubHeader: false
          });
  
          // Update category total
          categoryTotals[category] = (categoryTotals[category] || 0) + account.totalAssets + account.totalSpend + account.totalEarn;
      });
  
      // Ensure all categories and subcategories are included
      for (const category in accountCategories) {
          // Add category header
          if (!transformed.find((item) => item.account === category)) {
              transformed.push({
                  id: `${category}-header`,
                  account: category,
                  isHeader: true,
                  isSubHeader: false,
                  total: 0
              });
          }
  
          // Add subcategories and their accounts
          accountCategories[category].subcategories.forEach((subcategory) => {
              // Add subcategory header
              if (!transformed.find((item) => item.account === subcategory)) {
                  transformed.push({
                      id: `${category}-${subcategory}-header`,
                      account: subcategory,
                      isHeader: false,
                      isSubHeader: true,
                      total: 0
                  });
              }
  
              // Add individual accounts under the subcategory
              if (subcategoryDetails[subcategory]) {
                  subcategoryDetails[subcategory].forEach((account) => transformed.push(account));
              }
  
              // Calculate and add subcategory total
              const subcategoryTotal = (subcategoryDetails[subcategory] || []).reduce((sum, account) => sum + account.total, 0);
              transformed.push({
                  id: `${category}-${subcategory}-total`,
                  account: `${subcategory} Total`,
                  gl_code: '-',
                  total: subcategoryTotal,
                  isHeader: false,
                  isSubHeader: false
              });
          });
  
          // Add category total
          transformed.push({
              id: `${category}-total`,
              account: `${category} Total`,
              gl_code: '-',
              total: categoryTotals[category] || 0,
              isHeader: false,
              isSubHeader: false
          });
      }
  
      return transformed;
  };
  
    const columns = [
        {
            name: 'Account',
            selector: (row) => row.account,
            cell: (row) => {
                if (row.isHeader) {
                    return <strong style={{ fontWeight: 'bold' }}>{row.account}</strong>;
                }
                if (row.isSubHeader) {
                    return <span style={{ fontWeight: 'bold', paddingLeft: '30px' }}>{row.account}</span>;
                }
                return (
                  <span style={{ paddingLeft: '60px' }}>
                      <a href={`/account-details/${row.account_id}`} target="_blank" rel="noopener noreferrer">
                          {row.account}
                      </a>
                  </span>
              );
              
            }
        },
        {
            name: 'ACCOUNT Code',
            selector: (row) => row.gl_code || '-',
            cell: (row) => {
                return <span style={{ paddingLeft: '40px' }}>{row.gl_code}</span>;
            }
        },
        {
            name: 'Total',
            selector: (row) => row.total,
            right: true,
            cell: (row) => {
              const formattedTotal = row.total !== undefined ? 
                  new Intl.NumberFormat('en-US', { 
                      style: 'decimal', 
                      minimumFractionDigits: 2, 
                      maximumFractionDigits: 2 
                  }).format(row.total) : '';
              
              return (<span className='font-weight-bold'>{formattedTotal}</span>);
          }
          
        }
    ];

    return (
      <>
      <Card.Text className="align-items-center">
          
          <div>
            <span className='pr-3'>Filters:</span>
              {['3m', '6m', '1y', '2y'].map((range) => (
                  <Button
                      key={range}
                      onClick={() => setTabDateRange(range)}
                      style={{
                          marginRight: '5px',
                          padding: '5px',
                          backgroundColor: activeTab === range ? '#007bff' : '#f8f9fa',
                          color: activeTab === range ? 'white' : 'black',
                          border: activeTab === range ? 'none' : '1px solid #ced4da',
                          borderRadius: '5px',
                          cursor: 'pointer'
                      }}
                  >
                      {range === '3m'
                          ? 'Last 3 Months'
                          : range === '6m'
                          ? 'Last 6 Months'
                          : range === '1y'
                          ? 'Last 1 Year'
                          : 'Last 2 Years'}
                  </Button>
              ))}
              {/* </div>
          <div> */}
           <span className='px-3'>Date Range:</span>
              <Form.Label>
                  From: <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </Form.Label>
              <Form.Label style={{ marginLeft: '20px' }}>
                  To: <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </Form.Label>

              <span className='px-3'>Exports:</span>
          </div>
          <hr />
      </Card.Text>
         <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
            <p className='text-center'>As of DAte</p>
            <DataTable
                columns={columns}
                data={data}
                noHeader
                highlightOnHover
                striped
                // bordered
                dense
                customStyles={{
                    headRow: {
                        style: {
                            backgroundColor: '#279e6c', // Green background
                            color: '#fff', // White text
                            textTransform: 'uppercase', // Uppercase text
                            minHeight: '1.5rem',
                            paddingTop: '8px',
                            paddingBottom: '8px'
                        }
                    }
                }}
            />
        </div>
        </>
    );
};

export default BalanceSheetTable;

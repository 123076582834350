import React from 'react'
import OverDash from "./over"

const Overview = () => {
  return (
    <div>
      <OverDash />
    </div>
  )
}

export default Overview